import * as Types from "./clients.types";
import nullWhenEmptyStringOrUndefinied from "@/utilities/nullWhenEmptyStringOrUndefinied";

export const unpackSingleClient = (
  response: Types.IServerResponseSingleClient
): Types.IUIResponseSingleClient => ({
  id: response?.id,
  name: response?.name,
  organisation: response?.organisation,
  contactInfo: response?.contact_info
    ? {
        webpageUrl: response?.contact_info?.webpage_url,
        phone: response?.contact_info?.phone,
        language: response?.contact_info?.language,
        fullName: response?.contact_info?.full_name,
        country: response?.contact_info?.country,
        streetName: response?.contact_info?.street_name,
        city: response?.contact_info?.city,
        zipCode: response?.contact_info?.zip_code,
        email: response?.contact_info?.email,
      }
    : null,
  contactUser: response?.contact_user
    ? {
        phone: response?.contact_user?.phone,
        email: response?.contact_user?.email,
        name: response?.contact_user?.name,
      }
    : null,
  metaInfo: response?.meta_info
    ? {
        businessVertical: response?.meta_info?.business_vertical,
        timezone: response?.meta_info?.timezone,
        about: response?.meta_info?.about,
        businessDescription: response?.meta_info?.business_description,
        useCase: response?.meta_info?.use_case,
      }
    : null,
  createdAt: response?.created_at,
  modifiedAt: response?.modified_at,
  status: response?.status,
  enabled_for_chat_support: response?.enabled_for_chat_support || false,
  maxChannels: response?.max_channels,
});

export const unpackClientVatId = (
  response: Types.IServerResponseClientVatId
): Types.IUIResponseClientVatId => ({
  vatIdValue: response.vat_id_value,
  vatIdType: response.vat_id_type,
});

export const unpackClientsList = (
  response: Types.IServerResponseClientList
): Types.IUIResponseClientList => ({
  clients: response.clients.map((client) => unpackSingleClient(client)),
  total: response.total,
});

export const packClient = (
  request: Types.IClientFormValues
): Types.IServerRequestClient => ({
  name: nullWhenEmptyStringOrUndefinied(request.name),
  organisation: request.organisation,
  contact_info: {
    webpage_url: nullWhenEmptyStringOrUndefinied(request.webpageUrl),
    phone: nullWhenEmptyStringOrUndefinied(request.phone),
    language: nullWhenEmptyStringOrUndefinied(request.language),
    country: nullWhenEmptyStringOrUndefinied(request.country),
    street_name: nullWhenEmptyStringOrUndefinied(request.streetName),
    city: nullWhenEmptyStringOrUndefinied(request.city),
    zip_code: nullWhenEmptyStringOrUndefinied(request.zipCode),
    email: nullWhenEmptyStringOrUndefinied(request.contactInfoEmail),
  },
  contact_user: {
    phone: nullWhenEmptyStringOrUndefinied(request.userPhone),
    email: nullWhenEmptyStringOrUndefinied(request.email),
    name: nullWhenEmptyStringOrUndefinied(request.userName),
  },
  meta_info: {
    business_vertical: nullWhenEmptyStringOrUndefinied(
      request.businessVertical
    ),
    timezone: nullWhenEmptyStringOrUndefinied(request.timezone),
    about: nullWhenEmptyStringOrUndefinied(request.about),
    business_description: nullWhenEmptyStringOrUndefinied(
      request.businessDescription
    ),
    use_case: nullWhenEmptyStringOrUndefinied(request.useCase),
  },
});

export const packVatId = (
  request: Types.IUIResponseClientVatId
): Types.IServerResponseClientVatId => ({
  vat_id_value: request.vatIdValue,
  vat_id_type: request.vatIdType,
});

export const packFbCallback = (
  request: Types.IFbCallbackVlaues
): Types.IServerRequestFbCallback => ({
  input_token: request.accessToken,
  has_integration_layer: request.useIntegrationLayer || false,
  channel_origin: request.channelOrigin,
  hosting_platform_type: request.hostingPlatformType,
  tier: request.tier,
  bundleId: request.bundleId,
});

export const packChannelBundleMap = (
  request: Types.IChannelBundleMap
): Types.IServerChannelBundleMap => ({
  channel_id: request.channelId,
  bundle_id: request.bundleId,
});

export const packCreatePartnerChangeInfo = (
  request: Types.IPartnerChangeRequestFormValues
): Types.IServerPartnerChangeRequestFormValues => ({
  new_partner_id: request.newPartnerId,
  message_for_new_partner: request.message,
  channel_to_bundle_map: request.channelToBundleMap?.map(packChannelBundleMap),
});

export const unpackPartnerChangePreview = (
  response: Types.IServerResponsePartnerChangePreview
): Types.IUIResponsePartnerChangePreview => ({
  isChangeRequestValid: response.is_change_request_valid,
  invalidRules: response.invalid_rules,
  currentPayingType: response.current_paying_type,
  targetPayingType: response.target_paying_type,
  currentPartnerMessagingCurrency: response.current_partner_messaging_currency,
  targetPartnerMessagingCurrency: response.target_partner_messaging_currency,
  currentPartnerPlan: response.current_partner_plan,
  targetPartnerPlan: response.target_partner_plan,
});

export const unpackPaymentCheck = (
  response: Types.IServerResponsePaymentCheck
): Types.IUIResponsePaymentCheck => ({
  nextAction: response.next_action,
  path: response.path,
  data: response.data,
  message: response.message,
});
