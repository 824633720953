// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.INS_understandWhatWorksBest]: "Understand what works best",
  [keys.INS_trackedConversion]: "Tracked conversion",
  [keys.INS_conversationsFrom]: "Conversations from",
  [keys.INS_whereLeads]: "where leads",
  [keys.INS_editConversions]: "Edit conversion",
  [keys.INS_edit]: "Edit",
  [keys.INS_allSources]: "All sources",
  [keys.INS_incoming]: "Incoming",
  [keys.INS_outgoing]: "Outgoing",
  [keys.INS_organic]: "Organic messages",
  [keys.INS_sourceName]: "Source name",
  [keys.INS_sourceBreakdown]: "Source breakdown",
  [keys.INS_startedConversations]: "Started conversations",
  [keys.INS_conversionRate]: "Conversion rate",
  [keys.INS_convertedLeads]: "Converted leads",
  [keys.INS_invested]: "Invested",
  [keys.INS_revenue]: "Revenue",
  [keys.INS_downloadLeads]: "Download leads",
  [keys.INS_searchNumber]: "Search for a number",
  [keys.INS_downloadAllLeads]: "Leads",
  [keys.INS_saveEvent]: "Save Event",
  [keys.INS_saveEventSubtitle]:
    "Ensure exact character matching when tracking a WhatsApp message, for accurate results.",
  [keys.INS_notificationEventCreated]: "Event created successfully",
  [keys.INS_saveEventTooltip]: "Track WhatsApp message",
  [keys.INS_writeTheEvent]: "Write the event message",
  [keys.INS_saveEventLabel]: "WhatsApp message to be tracked",
  [keys.INS_confirm]: "Confirm",
  [keys.INS_downloadAllLeadsDescription]:
    "Download all leads registered within the selected time span, without any specific filters applied.",
  [keys.INS_leadsDescription]: "Leads that started a conversation",
  [keys.INS_percentageLeads]:
    "Percentage of leads that triggered the conversion event",
  [keys.INS_leadsConversion]: "Leads that triggered the conversion event",
  [keys.INS_investedDescription]:
    "Amount invested in triggering conversion events",
  [keys.INS_conversionCost]:
    "The cost associated with each successful lead acquisition, based on the amount invested",
  [keys.INS_revenueDescription]:
    "Amount of revenue generated through this conversion event",
  [keys.INS_returnDescription]:
    "Return On Ad Spend (ROAS) is the revenue generated from each unit of currency invested",
  [keys.INS_impressionsTitle]: "Impressions",
  [keys.INS_impressionsDescription]:
    "Number of times an ad was loaded on the page",
  [keys.INS_paidClicksTitle]: "Paid Clicks",
  [keys.INS_paidClicksDescription]: "Amount of clicks on a CTWA ad",
  [keys.INS_roasDescription]: "ROAS data not available.",
  [keys.INS_learnEnable]: "Learn how to enable",
  [keys.INS_costPerConversion]: "Cost Per Conversion",
  [keys.INS_revenueNotAvailable]: "Revenue data not available",
  [keys.INS_adsFrom]: "Ads from → All Ad Sets",
  [keys.INS_sourceInfo]: "Source information",
  [keys.INS_detailedInfo]: "Detailed information for the selected source",
  [keys.INS_typeLabel]: "Type",
  [keys.INS_typeInfo]: "Incoming Meta",
  [keys.INS_typeDescription]:
    "Incoming Meta sources are Click To WhatsApp Ad Campaigns.",
  [keys.INS_incomingCustom]: "Incoming Custom",
  [keys.INS_incomingCustomDescription]:
    "Incoming custom sources are entry points where the user initiated the conversation. These could be QR Codes, WA.me links, etc.",
  [keys.INS_giveThisSourceName]: "Give this source a name",
  [keys.INS_yesterday]: "Yesterday",
  [keys.INS_enableIt]: "Enable it",
  [keys.INS_steps]: "step(s)",
  [keys.INS_createNewFunnel]: "Create new funnel",
  [keys.INS_nameFunnel]: "Name funnel",
  [keys.INS_replaceEvent]: "Replace with another event",
  [keys.INS_selectEvent]: "Select an event",
  [keys.INS_removeEvent]: "Remove event from funnel",
  [keys.INS_mustNotExceed20]: "Name must not exceed 20 characters",
  [keys.INS_costPerAcquisition]: "Cost per Acquisition",
  [keys.INS_inThreadEvent]: "In-thread event",
  [keys.INS_offThreadEvent]: "Off-thread event",
  [keys.INS_funnelUpdated]: "Funnel updated",
  [keys.INS_sourceTypes]: "All sources",
  [keys.INS_sources]: "Sources",
  [keys.INS_sourcesDescription]:
    "Types of sources used to promote conversations",
  [keys.INS_messages]: "Messages",
  [keys.INS_folders]: "Folders",
  [keys.INS_ads]: "Ads",
  [keys.INS_metaClickToWhatsApp]: "CTWA Campaigns",
  [keys.INS_metaCTWA]: "Meta CTWA",
  [keys.INS_incomingCampaigns]: "Incoming messages",
  [keys.INS_outgoingTemplates]: "Outgoing messages",
  [keys.INS_open]: "Open",
  [keys.INS_noConversations]: "No conversations recorded from this source yet.",
  [keys.INS_connectAdAccount]: "Connect your AD account",
  [keys.INS_connect]: "Connect",
  [keys.INS_selectEventToViewDetails]:
    "Select an event to view details and data",
  [keys.INS_custom]: "Custom",
  [keys.INS_lastWeek]: "Last week",
  [keys.INS_last2Weeks]: "Last 2 weeks",
  [keys.INS_lastMonth]: "Last month",
  [keys.INS_cancel]: "Cancel",
  [keys.INS_apply]: "Apply",
  [keys.INS_selectEventToViewDetails]:
    "Select an event to view details and data",
  [keys.INS_bestRoas]: "Best",
  [keys.INS_bestRoasTooltip]:
    "Seems like this ad is performing considerably better than the others in this same Ad set.",
  [keys.INS_bestRoasTooltipLink]:
    "Learn how to improve the overall performance",
  [keys.INS_worstRoas]: "Worst",
  [keys.INS_worstRoasTooltip]:
    "Seems like this ad is performing considerably worse than the others in this same Ad set.",
  [keys.INS_worstRoasTooltipLink]: "Learn how to improve its performance",
  [keys.INS_orderingFilter]: "Ordering",
  [keys.INS_details]: "Details",
  [keys.INS_applyFunnel]: "Apply funnel",
  [keys.INS_funnelApplied]: "Funnel applied",
  [keys.INS_applyFunnelButtonTooltip]:
    "Fetch Conversion rate and Converted leads columns based on your Funnel events.",
  [keys.INS_applyFunnelDisabledButtonTooltip]:
    "Add at least 2 events on the Funnel to apply it on the table.",
};

export default translate;
