// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  //  [keys.WIZ_continueLabelWithHelp]: "translation_missing",
  //  [keys.WIZ_billingPlanTitle]: "translation_missing",
  //  [keys.WIZ_billingMethodTitle]: "translation_missing",
  //  [keys.WIZ_billingTermsTitle]: "translation_missing",
  //  [keys.WIZ_paymentMethodTitleStep1]: "translation_missing",
  //  [keys.WIZ_paymentMethodCountrySelectLabel]: "translation_missing",
  //  [keys.WIZ_paymentMethodCountrySelectLabel]: "translation_missing",
  //  [keys.WIZ_termsTitle]: "translation_missing",
  //  [keys.WIZ_numberWasSetUp]: "translation_missing",
  //  [keys.WIZ_connectInboxTitle]: "translation_missing",
  //  [keys.WIZ_connectInboxText]: "translation_missing",
  //  [keys.WIZ_goToHubButtonLabel]: "translation_missing",
  //  [keys.WIZ_channelPageCheckboxText1]: "translation_missing",
  //  [keys.WIZ_channelPageCheckboxText1]: "translation_missing",
  //  [keys.WIZ_channelPageCheckboxText3]: "translation_missing",
  //  [keys.WIZ_channelPageCheckboxText3]: "translation_missing",
  //  [keys.WIZ_channelPageSubtitle1]: "translation_missing",
  //  [keys.WIZ_channelPageSubtitle2]: "translation_missing",
  //  [keys.WIZ_waitingText1]: "translation_missing",
  //  [keys.WIZ_waitingText2]: "translation_missing",
  //  [keys.WIZ_channelTitle]: "translation_missing",
  //  [keys.WIZ_titleSpecifiedPermission]: "translation_missing",
  //  [keys.WIZ_titleUnspecifiedPermission]: "translation_missing",
  //  [keys.WIZ_saveSelection]: "translation_missing",
  //  [keys.WIZ_grantPermission]: "translation_missing",
  //  [keys.WIZ_optional]: "translation_missing",
  //  [keys.WIZ_successTitle]: "translation_missing",
  //  [keys.WIZ_successSubtitle1]: "translation_missing",
  //  [keys.WIZ_successSubtitle2]: "translation_missing",
  //  [keys.WIZ_successMainTitle]: "translation_missing",
  //  [keys.WIZ_successTitleWithoutBrandName]: "translation_missing",
  //  [keys.WIZ_successTitle1WithoutBrandName]: "translation_missing",
  //  [keys.WIZ_permissionsPageWarningMessage]: "translation_missing",
  //  [keys.WIZ_addThisNumber]: "translation_missing",
  //  [keys.WIZ_addSelected]: "translation_missing",
  //  [keys.WIZ_selectPlanTitle]: "translation_missing",
  //  [keys.WIZ_selectPlanSubtitle]: "translation_missing",
  //  [keys.WIZ_addNumberText]: "translation_missing",
  //  [keys.WIZ_selectedCount]: "translation_missing",
  //  [keys.WIZ_selectedCountNewNumbers]: "translation_missing",
  //  [keys.WIZ_welcomeToWABA]: "translation_missing",
  //  [keys.WIZ_facebookAndWhatsAppBusAccount]: "translation_missing",
  //  [keys.WIZ_accountsSuccessfullyCreated]: "translation_missing",
  //  [keys.WIZ_authorizePartnerApp]: "translation_missing",
  //  [keys.WIZ_numbers]: "translation_missing",
  //  [keys.WIZ_selectNumbers]: "translation_missing",
  //  [keys.WIZ_setupFbAndWba]: "translation_missing",
  //  [keys.WIZ_nextSteps]: "translation_missing",
  //  [keys.WIZ_companyInfoText]: "translation_missing",
  //  [keys.WIZ_cardDetailsError]: "translation_missing",
  //  [keys.WIZ_insufficientFundsError]: "translation_missing",
  //  [keys.WIZ_tryAgain]: "translation_missing",
  //  [keys.WIZ_transactionCancelled]: "translation_missing",
  //  [keys.WIZ_transactionFailed]: "translation_missing",
  //  [keys.WIZ_cardBlocked]: "translation_missing",
  //  [keys.WIZ_cardExpired]: "translation_missing",
  //  [keys.WIZ_amountMismatch]: "translation_missing",
  //  [keys.WIZ_cardInvalid]: "translation_missing",
  //  [keys.WIZ_paymentNotAllowed]: "translation_missing",
  //  [keys.WIZ_cardLowBalance]: "translation_missing",
  //  [keys.WIZ_noPaymentMethod]: "translation_missing",
  //  [keys.WIZ_cloudHostingIOInfo]: "translation_missing",
  //  [keys.WIZ_accountSetUpIssuesMessage]: "translation_missing",
  //  [keys.WIZ_accountCreationFailed]: "translation_missing",
  //  [keys.WIZ_updatePaymentDetails]: "translation_missing",
  //  [keys.WIZ_noPendingChannels]: "translation_missing",
  //  [keys.WIZ_verifyOwnership]: "translation_missing",
  //  [keys.WIZ_somethingWrong]: "translation_missing",
  //  [keys.WIZ_NumberVerificationTitle]: "translation_missing",
  //  [keys.WIZ_NumberVerificationDesc]: "translation_missing",
  //  [keys.WIZ_success]: "translation_missing",
  //  [keys.WIZ_successMessage]: "translation_missing",
  //  [keys.WIZ_paymentMethodError]: "translation_missing",
};

export default translate;
