// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.ERR_templateRenameExists]:
    "Não é possível renomear esse template. Já existe um template com esse nome. Por favor, insira um nome diferente.",
  [keys.ERR_noStripeCustomer]:
    "Não foi possível abrir as opções de cobrança. Sua conta de cobrança ainda não foi configurada. Por favor, entre em contato com o nosso time de suporte para seguir.",
  [keys.ERR_weAreSorry]: "Lamentamos",
  [keys.ERR_internalIssuesTeamNotified]:
    "Parece que estamos com alguns problemas internos. Nossa equipe foi notificada. Se você gostaria de ajudar, você pode descrever o que aconteceu abaixo.",
  [keys.ERR_reloadPage]: "Recarregar página",
  [keys.ERR_letUsKnowWhatHappened]: "Nos informe o que aconteceu",
  [keys.ERR_failedToGetCountry]: "Falha em obter o país",
  [keys.ERR_errorLoadingPaymentNotification]:
    "Erro ao carregar a Notificação de Pagamento",
  [keys.ERR_userNotFound]:
    "Nós não conseguimos encontrar um usuário com este endereço de e-mail. Por favor, verifique novamente. Se você quiser se cadastrar para uma nova conta, entre em contato com seu parceiro de integração ou visite o site 360dialog.com",
  [keys.ERR_partnerNotPermitted]:
    "Desculpe, o partner escolhido não tem permissão para usar o embedded signup. Por favor, entre em contato conosco",
  [keys.ERR_inputFields]: "Erro nos campos de entrada",
  [keys.ERR_fbConnectionError]:
    "Algo deu errado ao tentar conectar sua conta no Meta.",
  [keys.ERR_fbNoRegisteredEmail]:
    "Sua conta não tem um e-mail registrado, por favor adicione um à sua conta do Meta.",
  [keys.ERR_userNoExists]:
    "Este usuário não existe. Por favor, cadastre-se através do aplicativo do seu partner ou do 360dialog.com",
  [keys.ERR_addFundsNumber]:
    "Não é possível adicionar fundos a números de erro",
  [keys.ERR_autoRenewalNumber]:
    "Não é possível editar as configurações de renovação automática para números de erro",
  [keys.ERR_stripeErrorNote]:
    "Nós encontramos um erro ao carregar os scripts do stripe. Por favor, recarregue a página e tente novamente.",
  [keys.ERR_filterOptionsErrorNote]:
    "Nós encontramos um erro ao carregar as opções de idioma e fuso horário do filtro. Por favor, recarregue a página e tente novamente.",
  [keys.ERR_publicDataErrorText]:
    "A página não pode ser carregada corretamente. Por favor, tente novamente recarregando a página. {{break}}} Caso este problema persista, por favor, revise nossa página de status via {{statusPageLink}} ou entre em contato com nosso suporte.",
  [keys.ERR_somethingUnexpected]:
    "Algo inesperado aconteceu, por favor tente novamente mais tarde",
  [keys.ERR_phoneRegistrationError]:
    "O código de verificação não foi definido durante a etapa de conclusão do registro. Verifique se o código é válido e tente novamente.",
  [keys.ERR_userWithNoClientPermissionError]:
    "O processo não pôde ser concluído. Seu usuário não tem permissão para agir em nome dessa conta de cliente. Verifique se você está acessando o URL correto, incluindo o ID de cliente correto, que pertence ao seu usuário. Em caso de dúvida, saia e entre novamente usando uma janela privada do navegador.",
};

export default translate;
