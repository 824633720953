// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.A_SomethingWentWrongError]: "Algo deu errado",
  [keys.A_SelectDefaultPlaceholder]: "Selecione...",
  [keys.A_phoneNumber]: "Número de telefone",
  [keys.A_waba]: "Conta de WhatsApp Business",
  [keys.A_client]: "Cliente",
  [keys.A_partner]: "Parceiro",
  [keys.A_documentation]: "Documentação",
  [keys.A_email]: "Email",
  [keys.A_password]: "Senha",
  [keys.A_back]: "Voltar",
  [keys.A_sortBy]: "Organizar por",
  [keys.A_date]: "data",
  [keys.A_status]: "status",
  [keys.A_displayName]: "Display name",
  [keys.A_filterByStatus]: "filtrar por status",
  [keys.A_searchHere]: "Buscar aqui",
  [keys.A_account]: "Conta ",
  [keys.A_accountName]: "Nome da conta",
  [keys.A_search]: "Buscar",
  [keys.A_cancel]: "Cancelar",
  [keys.A_continue]: "Continuar",
  [keys.A_close]: "Fechar",
  [keys.A_delete]: "Deletar",
  [keys.A_edit]: "Editar",
  [keys.A_and]: "e",
  [keys.A_name]: "Nome",
  [keys.A_deletePlaceholder]: "Digite <DELETE> para confirmar",
  [keys.A_deleteHelptextDefault]:
    "Esta ação não pode ser desfeita. Você tem certeza de que quer continuar?",
  [keys.A_viewDocs]: "Abrir a documentação",
  [keys.A_defaultLanguage]: "Idioma padrão",
  [keys.A_save]: "Salvar",
  [keys.A_learnMore]: "Mais informações",
  [keys.A_audiencesLearnMore]: "Aprenda Mais",
  [keys.A_address]: "Endereço",
  [keys.A_description]: "Descrição",
  [keys.A_website]: "Website",
  [keys.A_deleteImage]: "Deletar imagem",
  [keys.A_dragYourImage]: "Arraste sua imagem aqui ou",
  [keys.A_browse]: "buscar",
  [keys.A_supportsFormats]: "Suportamos os formatos jpg, png e svg",
  [keys.A_upload]: "Upload",
  [keys.A_imageMaxSize]: "A imagem deve ter menos de 3MB",
  [keys.A_paymentMethodCountrySelectLabel]:
    "Selecione seu país para ver os métodos de pagamento disponíveis.",
  [keys.A_intCreditCard]: "Cartão de crédito internacional",
  [keys.A_paymentMethodSelectLabel]:
    "Selecione o seu método de pagamento de preferência",
  [keys.A_savePaymentMethod]: "Salvar método de pagamento",
  [keys.A_country]: "País",
  [keys.A_cardExistingText]: "Você já cadastrou um cartão de crédito.",
  [keys.A_addAnotherCardButton]: "Adicionar outro cartão de crédito",
  [keys.A_creditCardFormHelptext]:
    "Por favor insira as informações do cartão de crédito abaixo.",
  [keys.A_submitCardButton]: "Cadastrar cartão",
  [keys.A_termsStep1]: "Concordo com os",
  [keys.A_termsOfService]: "termos de serviço",
  [keys.A_termsConnect]: "e com",
  [keys.A_terms360exhibit1]: "exhibit 1 - Termos de uso da 360dialog",
  [keys.A_terms360exhibit3]: "exhibit 3 - Tarifas da 360dialog",
  [keys.A_waTermsStep1]: "Li e e stou de acordo com",
  [keys.A_terms360exhibit2]:
    "exhibit 2 - Termos do Meta para clientes do WhatsApp",
  [keys.A_partnerTermsConnect]: "e com",
  [keys.A_privacyPolicy]: "política de privacidade",
  [keys.A_partnersTermsAndConditions]: "termos e condições",
  [keys.A_selectNumberTitle]: "Selecionar número",
  [keys.A_addNewNumber]: "Adicionar novo número",
  [keys.A_or]: "ou",
  [keys.A_tooltipEmbeddedSignup]:
    "Inscrições usando este formulário seguirão o fluxo de ativação de Embedded Signup. Ative contas com menos de 10 minutos, e finalize o processo de verificação depois. <LINK>https://docs.360dialog.com/embedded-signup | Mais detalhes<LINK>",
  [keys.A_tooltipClassicSignup]:
    "Inscrições usando este formulário seguirão o fluxo clássico de ativação. Empresas precisam estar completamente verificadas pelo Meta antes de acessar a API. <LINK>https://docs.360dialog.com/submission-process | Mais detalhes<LINK>",
  [keys.A_loading]: "Carregando",
  [keys.A_notImage]: "O arquivo enviado deve ser nos formatos jpg, png ou svg",
  [keys.A_tooltipNumberPortingSignup]:
    "Envios através desse formulário permitirão que clientes criem uma conta aravés da portabilidade de um número de outro BSP <LINK>https://docs.360dialog.com/360-client-hub/number-porting | Leia mais.<LINK>",
  [keys.A_FbmId]: "Business Manager ID",
  [keys.A_buttonLabelReturnToHub]: "Voltar para o 360dialog Client Hub",
  //  [keys.A_notificationNoPermission]: "translation_missing",
  //  [keys.A_textNoPermission]: "translation_missing",
  [keys.A_continueNext]: "Continuar para o próximo passo",
  [keys.A_pleaseTryAgain]:
    "Estamos no momento com problemas no login. Por favor tentar novamente em 30 minutos.",
  [keys.A_clientsSearchPlaceholder]: "Procurar nome",
  [keys.A_terms]: "Termos",
  [keys.A_userNotExists]:
    "Este usuário não existe. Inscreva-se através do aplicativo do seu parceiro ou 360dialog.com",
  [keys.A_verificationError]: "Algo deu errado, por favor tente novamente",
  [keys.A_partnerIsBlocked]: "Parceiro está bloqueado para novas submissões",
  [keys.A_urlCopiedToClipboard]: "URL copiado para a Área de Transferência",
  [keys.A_latestSearch]: "Últimas buscas",
  [keys.A_loadingBillingPortal]: "Carregando o portal de faturamento",
  [keys.NOT_allNotifications]: "Tudo",
  [keys.NOT_archivedNotifications]: "Arquivado",
  [keys.NOT_closeButtonTooltip]: "Arquivar essa notificação",
  [keys.NOT_archiveAllBtn]: "Arquivar tudo",
  [keys.NOT_clientAdded]:
    "O novo cliente será adicionado ao seu hub de parceiros",
  [keys.A_errorDownloadingCSV]:
    "Ocorreu um erro ao tentar fazer o download do arquivo CSV. Por favor, tente novamente mais tarde.",
  [keys.A_financialReportSentToEmail]:
    "O relatório financeiro será enviado para seu endereço de e-mail em breve.",
  [keys.A_errorGeneratingFinancialReport]:
    "Ocorreu um erro ao gerar o relatório financeiro. Por favor, tente novamente mais tarde.",
  [keys.A_exportAllContactData]: "Exportar todos os dados de contato",
  [keys.A_generateFinancialReport]: "Gerar relatório financeiro",
  [keys.A_generateInvoices]: "Gerar faturas",
  [keys.A_templateMessages]: "Mensagens de templates",
  [keys.A_generateTemplateInvoices]: "Gerar faturas de template",
  [keys.A_generateTemplateReport]: "Gerar relatório de template",
  [keys.A_conversationBasedPricing]: "Preços baseados em conversação",
  [keys.A_generateCBPInvoices]: "Gerar faturas do CBP",
  [keys.A_generateCBPDailyReport]: "Gerar relatório diário CBP",
  [keys.A_generateCBPMonthlyReport]: "Gerar relatório mensal CBP",
  [keys.A_invoiceCreationTriggered]:
    "Criação de faturas acionadas. As faturas serão enviadas por e-mail.",
  [keys.A_invoiceDateNeedsToBeFirstOrLast]:
    "A data de faturamento precisa ser o primeiro ou o último dia de um mês.",
  [keys.A_dateOfInvoice]: "Data da fatura",
  [keys.A_selectInvoicingDate]: "Selecione a data da fatura",
  [keys.A_reportCreationTriggered]:
    "Criação de relatório acionado. O relatório será enviado via e-mail.",
  [keys.A_triggerInvoiceCreation]: "Acionar criação do relatório",
  [keys.A_selectReportDate]: "Selecionar data do relatório",
  [keys.A_dateOfReport]: "Data do relatório",
  [keys.A_monthlyReportCreationTriggered]:
    "Criação de relatórios mensais acionados. O relatório será enviado via e-mail.",
  [keys.A_dailyReportCreationTriggered]:
    "Criação de relatórios diários acionados. O relatório será enviado via e-mail.",
  [keys.A_selectMonthlyReportDate]: "Selecione a data do relatório mensal",
  [keys.A_selectDailyReportDate]: "Selecione a data do relatório diário",
  [keys.A_somethingWentWrongnTryAgain]:
    "Algo deu errado, por favor tente mais tarde",
  [keys.A_linkURL]: "Link URL",
  [keys.A_parameters]: "Parâmetros",
  [keys.A_imageRecommendation]:
    "Recomendação: tamanho máximo de 5MB e tamanho de imagem de 640x640.",
  [keys.A_displayLogoAndDeepLinkNotice]:
    "É assim que os clientes verão seu logotipo e nome de marca, em vários lugares dentro do 360dialog Client Hub.",
  [keys.A_attentionToShowLogoAndBrandSet]:
    "O elemento a seguir só será exibido se tanto o logotipo da empresa quanto o nome da marca forem definidos.",
  [keys.A_attentionToShowIfURLProvided]:
    "O seguinte elemento só aparecerá se for fornecida uma deeplink URL.",
  [keys.A_companyLogo]: "Logotipo da empresa",
  [keys.A_uploadLogo]: "Carregar Logotipo",
  [keys.A_profilePreview]: "Pré-visualização do perfil",
  [keys.A_deeplinkURL]: "Deeplink URL",
  [keys.A_logoChangeSuccess]: "Logotipo alterado com sucesso",
  [keys.A_deeplinkURLToolTipContent]:
    "Se este valor for definido, os usuários terão a opção de clicar em um botão após terem criado sua chave API, que os levará de volta à sua aplicação, onde poderão colocar a chave API.",
  [keys.A_logoAndBrandNameVariantOne]: "Variante 1: Formulário de migração",
  [keys.A_logoAndBrandNameVariantTwo]: "Variante 2: logotipo e nome da marca",
  [keys.A_featured]: "Destaque",
  [keys.A_verifyHuman]: "Verificar se você é humano",
  [keys.A_insightsAndFunds]: "Insights e fundos",
  [keys.A_letsDoIt]: "Vamos lá!",
  [keys.A_paymentPlan]: "Selecione o seu plano de pagamento",
  [keys.A_newNumberPlan]: "Selecione o seu plano de pagamento para o número",
  [keys.A_betterSla]: "Melhor SLA",
  [keys.A_higherScalability]: "Escalabilidade maior",
  [keys.A_verifyBusiness]: "Verifique sua empresa!",
  [keys.A_verifyFBM]:
    "Dirija-se ao Gerente de Negócios do Meta para começar com o processo de verificação de negócios.",
  [keys.A_accessCompanyFb]:
    "Para continuar você precisará <BOLD>acessar a conta de negócios de sua empresa no Meta.<BOLD>",
  [keys.A_continueWithFbButton]:
    "Ao clicar no botão Continuar com Meta abaixo, você pode:",
  [keys.A_addNumbers]: "Adicionar números",
  [keys.A_addNumber]: "Adicionar número",
  [keys.A_addFirstNumber]:
    "Você ainda não adicionou nenhum número de telefone. Adicione seu primeiro número para começar a usar a WhatsApp Business Platform.",
  [keys.A_termsAndConditions]: "Termos e condições",
  [keys.A_paymentDetails]: "Detalhes do pagamento",
  [keys.A_facebookSetup]: "Configuração do Meta",
  [keys.A_termsAndPriceList1]:
    "Eu concordo com os Termos de Serviço <LINK>https://www.360dialog.com/contact#privacy-policy | 360dialog Terms of Service<LINK>, e com o",
  [keys.A_termsAndPriceList2]:
    "<LINK>https://www.360dialog.com/whatsapp-business-api?origin=new_hub#pricing | Lista de preços 360dialog<LINK>.",
  [keys.A_user]: "Usuário",
  [keys.A_insightsPageTitle]: "360dialog - Insights",
  [keys.A_insightsPageDescription]: "Ver detalhes e fundos",
  [keys.A_supportPageTitle]: "360dialog - Suporte",
  [keys.A_supportPageDescription]: "Suporte e contato",
  [keys.A_numberPageTitle]: "360dialog - Seu Número",
  [keys.A_numberPageDescription]: "Veja seu número",
  [keys.A_detailsPageTitle]: "360dialog - Detalhes do Canal",
  [keys.A_detailsPageDescription]: "Ver detalhes sobre um canal",
  [keys.A_changeDisplayName]: "Alterar display name",
  [keys.A_pleaseContinueRegistration]:
    "Por favor, continue o registro do seu número",
  [keys.A_continueRegistration]: "Continuar o registro",
  [keys.A_continueVerification]: "Continuar a verificação",
  [keys.A_numbers]: "Número",
  [keys.A_inappropriatePassword]:
    "Não foi possível criar o usuário. Motivo: A senha contém informações do usuário",
  [keys.A_businessVerifiedButton]: "Eu já verifiquei meu negócio",
  [keys.A_businessNotVerifiedButton]: "Continuar sem verificação",
  [keys.A_chatSupportEnabled]: "Suporte por chat habilitado",
  [keys.A_acceptAndClose]: "Aceitar e fechar",
  [keys.A_fullInfoDetails]:
    "A informação completa pode ser acessada {{hereLink}}",
  [keys.A_here]: "aqui",
  [keys.A_subProcessorPopupTitle]:
    "Informações sobre a adição de novos Sub-Processadores",
  [keys.A_thatsAllNotifications]:
    "Essas são todas as notificações que você recebeu nos últimos 30 dias.",
  [keys.A_errorPaymentToken]: "Erro ao obter o token de pagamento",
  [keys.A_maintenance]:
    "Estamos passando por manutenção e voltaremos em breve, desculpe pelo inconveniente. Para saber mais, visite nossa <LINK>https://status.360dialog.com | página de status.<LINK>",
  [keys.A_userMaintenance]: "Manutenção do usuário",
  [keys.A_actions]: "Ações",
  [keys.A_signals]: "Sinais",
  [keys.A_manage]: "Gerenciar",
  [keys.A_qualityRating]: "Índice de qualidade",
  [keys.A_variantIOTitle]: "Variante 2: Integração de onboarding",
  //  [keys.A_allowClientsAddNumber]: "translation_missing",
  //  [keys.A_allowClientsAddNumberTooltip]: "translation_missing",
  //  [keys.A_allow]: "translation_missing",
  //  [keys.A_restrict]: "translation_missing",
  //  [keys.A_restrictClientMessage]: "translation_missing",
  //  [keys.A_allowClientMessage]: "translation_missing",
  //  [keys.A_updatedAllowedMessage]: "translation_missing",
  //  [keys.A_updatedRestrictMessage]: "translation_missing",
  //  [keys.A_infoDetailsPriceChange]: "translation_missing",
  //  [keys.A_priceChangePopupTitle]: "translation_missing",
  //  [keys.A_selectEventType]: "translation_missing",
  //  [keys.A_whereWasEvent]: "translation_missing",
  //  [keys.A_eventWithinConversation]: "translation_missing",
  //  [keys.A_eventOutsideConversation]: "translation_missing",
  //  [keys.A_messagingEvent]: "translation_missing",
  //  [keys.A_externalEvent]: "translation_missing",
  //  [keys.A_enterNumber]: "translation_missing",
  //  [keys.A_yourNumber]: "translation_missing",
  //  [keys.A_iHaveNumber]: "translation_missing",
  //  [keys.A_iDontHaveNumber]: "translation_missing",
  //  [keys.A_selectDoYouHaveNumber]: "translation_missing",
  //  [keys.A_doYouHaveNumber]: "translation_missing",
  //  [keys.A_youNeedNumberToFinalizeJourney]: "translation_missing",
  //  [keys.A_createNumber]: "translation_missing",
  //  [keys.A_basicSubPlan]: "translation_missing",
  //  [keys.A_regularSubPlan]: "translation_missing",
  //  [keys.A_premiumSubPlan]: "translation_missing",
  //  [keys.A_cloudAPIHosting]: "translation_missing",
  //  [keys.A_HostingService]: "translation_missing",
  //  [keys.A_TicketBasedSupport]: "translation_missing",
  //  [keys.A_OnPremiseHosting]: "translation_missing",
  //  [keys.A_RTChat]: "translation_missing",
  //  [keys.A_WABAReq]: "translation_missing",
  //  [keys.A_AdsFunnelTracking]: "translation_missing",
  //  [keys.A_recommended]: "translation_missing",
  //  [keys.A_choosePlan]: "translation_missing",
  //  [keys.A_SelectPlan]: "translation_missing",
  //  [keys.A_includes]: "translation_missing",
  //  [keys.A_prevTierPlus]: "translation_missing",
  //  [keys.A_continueWithSelectedPlan]: "translation_missing",
  //  [keys.A_activityPageDescription]: "translation_missing",
  //  [keys.A_activityPageTitle]: "translation_missing",
  [keys.A_activity]: "Atividade",
  //  [keys.A_noActivityFound]: "translation_missing",
  //  [keys.A_noActivitySeen]: "translation_missing",
  //  [keys.A_allChannels]: "translation_missing",
  //  [keys.A_addNumberToSeeActivity]: "translation_missing",
  //  [keys.A_addNumberOverview]: "translation_missing",
  //  [keys.A_insights]: "translation_missing",
  //  [keys.A_expenseTooltip]: "translation_missing",
  //  [keys.A_downloadContacts]: "translation_missing",
  //  [keys.A_downloadContactsList1]: "translation_missing",
  //  [keys.A_downloadContactsList2]: "translation_missing",
  //  [keys.A_couldYouTellUsMore]: "translation_missing",
  //  [keys.A_downloadCsv]: "translation_missing",
  //  [keys.A_nameTheSource]: "translation_missing",
  //  [keys.A_nameTheSourceDescription]: "translation_missing",
  //  [keys.A_sourceName]: "translation_missing",
  //  [keys.A_message]: "translation_missing",
  //  [keys.A_saveSource]: "translation_missing",
  //  [keys.A_inviteMarketer]: "translation_missing",
  //  [keys.A_inviteMarketer1]: "translation_missing",
  //  [keys.A_coworkerEmail]: "translation_missing",
  //  [keys.A_invite]: "translation_missing",
  //  [keys.A_anInviteWasSent]: "translation_missing",
  //  [keys.A_messageDetails]: "translation_missing",
  //  [keys.A_checkSelectedMessages]: "translation_missing",
  //  [keys.A_sourceInvestment]: "translation_missing",
  //  [keys.A_investmentMade]: "translation_missing",
  //  [keys.A_amountCurrency]: "translation_missing",
  //  [keys.A_investedAmount]: "translation_missing",
  //  [keys.A_saveInvestment]: "translation_missing",
  //  [keys.A_requestPartnerAccess]: "translation_missing",
  //  [keys.A_trackEvent]: "translation_missing",
  //  [keys.A_selectPoolConversations]: "translation_missing",
  //  [keys.A_billingPageTitle]: "translation_missing",
  //  [keys.A_billingPageDescription]: "translation_missing",
  //  [keys.A_coworkerName]: "translation_missing",
  //  [keys.A_sentTo]: "translation_missing",
  //  [keys.A_viewConversationsInsights]: "translation_missing",
  //  [keys.A_insightsPageTitle]: "translation_missing",
  //  [keys.A_insightsPageDescription]: "translation_missing",
  //  [keys.A_measurePerformance]: "translation_missing",
  //  [keys.A_selectEventType]: "translation_missing",
  //  [keys.A_whereWasEvent]: "translation_missing",
  //  [keys.A_eventWithinConversation]: "translation_missing",
  //  [keys.A_eventOutsideConversation]: "translation_missing",
  //  [keys.A_messagingEvent]: "translation_missing",
  //  [keys.A_externalEvent]: "translation_missing",
  //  [keys.A_changeEventType]: "translation_missing",
  //  [keys.A_adAccountsPageTitle]: "translation_missing",
  //  [keys.A_adAccountsPAgeDescription]: "translation_missing",
  //  [keys.A_permissions]: "translation_missing",
  //  [keys.A_preferences]: "translation_missing",
  [keys.A_fundsPageTitle]: "360dialog - Fundos",
  [keys.A_funds]: "Fundos",
  [keys.A_archived]: "Arquivado",
  [keys.A_rename]: "Renomear",
  [keys.A_options]: "Opções",
  [keys.A_adminHeaderInfo]:
    "Voce agora está acessando como um usuário do cliente: <client_name>. Para acessar como outro cliente, altere clientId e partnerId na URL.",
  //  [keys.A_funnels]: "translation_missing",
  [keys.A_Integrations]: "Integrações",
  [keys.A_integrationsPageTitle]: "Integrações",
};

export default translate;
