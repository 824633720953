// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.WIZ_continueLabelWithHelp]:
    "Um fortzufahren, führe die erforderlichen Schritte aus",
  [keys.WIZ_billingPlanTitle]: "Wähle einen Tarif.",
  [keys.WIZ_billingMethodTitle]: "Zahlungsdetails hinzufügen",
  [keys.WIZ_billingTermsTitle]:
    "Akzeptiere die Allgemeinen Geschäftsbedingungen",
  [keys.WIZ_paymentMethodTitleStep1]: "1. Zahlungsmethode auswählen",
  [keys.WIZ_paymentMethodCountrySelectLabel]: "2. Zur Kasse gehen",
  [keys.WIZ_paymentMethodCountrySelectLabel]:
    "Wähle dein Land aus, um die verfügbaren Zahlungsarten anzuzeigen.",
  [keys.WIZ_termsTitle]: "360dialogs Geschäftsbedingungen:",
  [keys.WIZ_numberWasSetUp]:
    "Deine Nummer <NUMMER> wurde erfolgreich eingerichtet.",
  [keys.WIZ_connectInboxTitle]: "Beende dein Onboarding mit get.chat",
  [keys.WIZ_connectInboxText]:
    "Um deinen get.chat-Posteingang einzurichten, folge bitte den Anweisungen in der E-Mail, die du inzwischen erhalten haben solltest. Dein WhatsApp Business Account ist bereits eingerichtet und verbunden, so dass du sofort mit der Nutzung des Posteingangs beginnen kannst.",
  [keys.WIZ_goToHubButtonLabel]: "Weiter zum 360dialog Client Hub",
  [keys.WIZ_channelPageCheckboxText1]:
    "👤 Du hast ein persönliches Meta-Konto.",
  [keys.WIZ_channelPageCheckboxText1]:
    "📞 Die Nummer, mit der du dich verbinden willst, wird derzeit nicht von einem anderen WhatsApp-Konto verwendet.",
  [keys.WIZ_channelPageCheckboxText3]:
    "📲 Durch den Empfang von SMS oder internationalen Anrufen, kannst du dich als Inhaber der Nummer verifizieren.",
  [keys.WIZ_channelPageCheckboxText3]:
    "🖋️ Du hast bereits ein Meta-Geschäftskonto, das verifiziert ist.",
  [keys.WIZ_channelPageSubtitle1]:
    "Im nächsten Schritt musst du deine Konten einrichten, indem du deine Meta-Anmeldedaten verwendest und den angegebenen Schritten folgst.",
  [keys.WIZ_channelPageSubtitle2]:
    "Danach werden wir automatisch alles für dich einrichten und du wirst in der Lage sein, die WhatsApp Business API zu nutzen.",
  [keys.WIZ_waitingText1]:
    "Wir sind gerade dabei, deine eigene Serverinstanz einzurichten, so dass du in wenigen Minuten mit der Nutzung der WhatsApp Business API beginnen kannst.",
  [keys.WIZ_waitingText2]:
    "Wir starten deinen gehosteten WhatsApp Business API-Client.",
  [keys.WIZ_channelTitle]:
    "Bitte stelle sicher, dass du die folgenden Voraussetzungen erfüllst.",
  [keys.WIZ_titleSpecifiedPermission]:
    "Dein Partner bittet um die Erlaubnis, auf die WhatsApp Business API für die folgende Nummer zuzugreifen:",
  [keys.WIZ_titleUnspecifiedPermission]:
    "Wähle die Nummern aus, für die du deinem Partner die Erlaubnis erteilen möchtest, auf die WhatsApp Business API zuzugreifen.",
  [keys.WIZ_saveSelection]: "Auswahl speichern",
  [keys.WIZ_grantPermission]: "Erlaubnis erteilen",
  [keys.WIZ_optional]: "Optional",
  [keys.WIZ_successTitle]:
    "Du hast dein 360dialog-Konto erfolgreich mit der WhatsApp Business API verbunden.",
  [keys.WIZ_successSubtitle1]:
    "Du wirst in 5 Sekunden automatisch zu <PARTNER> zurückkehren.",
  [keys.WIZ_successSubtitle2]:
    "Bis dahin, bitte dieses Fenster nicht schließen.",
  [keys.WIZ_successMainTitle]: "Partnerantrag genehmigt!",
  [keys.WIZ_successTitleWithoutBrandName]:
    "Du hast soeben erfolgreich eine Verbindung zu deinem 360dialog-Konto hergestellt.",
  [keys.WIZ_successTitle1WithoutBrandName]:
    "Du wirst automatisch in 5 Sekunden zurückgekehren.",
  [keys.WIZ_permissionsPageWarningMessage]:
    "Es wurde die Erlaubnis für die Nummer <NUMMER> angefordert, die in deinem 360dialog-Konto nicht vorhanden ist. Möchtest du sie jetzt hinzufügen? Andernfalls wähle unten eine andere Nummer aus.",
  [keys.WIZ_addThisNumber]: "Diese Nummer hinzufügen",
  [keys.WIZ_addSelected]: "Auswahl hinzufügen",
  [keys.WIZ_selectPlanTitle]: "Wähle deinen Tarif.",
  [keys.WIZ_selectPlanSubtitle]:
    "Es sind nur noch wenige Schritte, bis du anfangen kannst, mit deinen Kunden zu chatten. Lass uns mit den Grundlagen beginnen.",
  [keys.WIZ_addNumberText]:
    "Optional kannst du auch <BUTTON>jetzt eine neue Nummer hinzufügen<BUTTON>.",
  [keys.WIZ_selectedCount]: "Ausgewählte <NUMBER> Nummern",
  [keys.WIZ_selectedCountNewNumbers]: "<NUMBER> neue Nummern ausgewählt",
  [keys.WIZ_welcomeToWABA]: "Willkommen bei der WhatsApp Business API!",
  [keys.WIZ_facebookAndWhatsAppBusAccount]:
    "Facebook- und WhatsApp-Business-Konten",
  [keys.WIZ_accountsSuccessfullyCreated]: "Konten erfolgreich erstellt!",
  [keys.WIZ_authorizePartnerApp]: "Partner-Anwendung autorisieren",
  [keys.WIZ_numbers]: "Nummern",
  [keys.WIZ_selectNumbers]: "Nummern auswählen",
  [keys.WIZ_setupFbAndWba]: "Facebook- und WhatsApp-Geschäftskonten einrichten",
  [keys.WIZ_nextSteps]:
    "In den nächsten Schritten musst du deine Konten einrichten, indem du deine Meta-Zugangsdaten verwendest und den angegebenen Schritten folgst. Außerdem musst du einige Zahlungsinformationen angeben.",
  [keys.WIZ_companyInfoText]:
    "Zuerst brauchen wir einige Informationen über dein Unternehmen.",
  [keys.WIZ_cardDetailsError]:
    "Zahlungsfehler. Bitte überprüfe deine Kartendaten und versuche es erneut.",
  [keys.WIZ_insufficientFundsError]:
    "Zahlungsfehler. Bitte überprüfe, ob du genügend Guthaben auf der Karte hast und versuche es erneut.",
  [keys.WIZ_tryAgain]: "Erneut versuchen",
  [keys.WIZ_transactionCancelled]:
    "Die Transaktion wurde abgelehnt. Bitte versuche es noch einmal.",
  [keys.WIZ_transactionFailed]:
    "Die Transaktion konnte nicht durchgeführt werden. Bitte versuche es noch einmal.",
  [keys.WIZ_cardBlocked]:
    "Die für die Transaktion verwendete Karte ist gesperrt. Bitte gib eine aktive Karte an, und versuche es noch einmal.",
  [keys.WIZ_cardExpired]:
    "Die für die Transaktion verwendete Karte ist abgelaufen. Bitte gib eine aktive Karte an, und versuche es noch einmal.",
  [keys.WIZ_amountMismatch]:
    "Während des Transaktionsvorgangs ist ein falscher Zahlungsbetrag festgestellt worden. Bitte versuche es noch einmal.",
  [keys.WIZ_cardInvalid]:
    "Die angegebene Kartennummer ist falsch oder ungültig. Bitte gib gültige Daten ein und versuche es noch einmal.",
  [keys.WIZ_paymentNotAllowed]:
    "Deine Bank unterstützt diese Art von Transaktion nicht oder erlaubt sie nicht. Bitte verwende die Zahlungsmethode einer anderen Bank.",
  [keys.WIZ_cardLowBalance]:
    "Das Guthaben auf der Karte reicht nicht aus, um den zu zahlenden Betrag zu decken. Füge Geld hinzu oder verwende eine andere Karte und versuche es noch einmal.",
  [keys.WIZ_noPaymentMethod]:
    "Keine Zahlungsmethode festgelegt. Bitte füge Unternehmensdaten und Kreditkarteninformationen hinzu",
  [keys.WIZ_cloudHostingIOInfo]:
    'Bitte beachte, dass du in der Dropdown-Liste "WhatsApp Business-Konto auswählen" nur die Option "WhatsApp Business-Konto erstellen" auswählen kannst. Auch wenn ein bestehendes WhatsApp Business-Konto standardmäßig ausgewählt ist, ändere es bitte in "WhatsApp Business-Konto erstellen" und klicke dann auf Weiter. Andernfalls wird die Erstellung der WhatsApp-Nummer fehlschlagen.',
  [keys.WIZ_accountSetUpIssuesMessage]:
    "Wir konnten deinen WhatsApp Account nicht erstellen, da technische Probleme aufgetreten sind. Bitte kontaktiere unseren Support um Hilfe zu erhalten.",
  [keys.WIZ_accountCreationFailed]: "Kontoerstellung fehlgeschlagen!",
  [keys.WIZ_updatePaymentDetails]: "Zahlungsdetails aktualisieren",
  [keys.WIZ_noPendingChannels]:
    "Für diesen Kunden sind keine Kanäle zur Genehmigung anhängig",
  [keys.WIZ_verifyOwnership]: "Eigentümerschaft überprüfen",
  [keys.WIZ_somethingWrong]:
    "Etwas ist schief gelaufen? Kontaktiere deinen Partner zur Klärung",
  [keys.WIZ_NumberVerificationTitle]: "Überprüfung des Eigentums an der Nummer",
  [keys.WIZ_NumberVerificationDesc]:
    "Dein Partner {{Partnername}} möchte dir Zugang zu den unten aufgeführten Telefonnummern gewähren. Du hast die Möglichkeit, sie zu verwalten und über unseren 360dialog Client Hub Einblicke zu erhalten. Bitte überprüfe die Eigentümerschaft der Nummern.",
  [keys.WIZ_success]: "Es hat geklappt!",
  [keys.WIZ_successMessage]:
    "Du hast deine Nummern erfolgreich aktiviert. Dein Integrationspartner {{Partnername}} wird benachrichtigt, so dass du in Kürze seine Dienste mit WhatsApp nutzen kannst. Du kannst dieses Fenster sicher schließen.",
  [keys.WIZ_paymentMethodError]:
    "Fehler beim Aktualisieren der Zahlungsmethode. Überprüfe deine Angaben und versuche es erneut.",
};

export default translate;
