// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.ERR_templateRenameExists]:
    "Ya existe una plantilla con este nombre. Por favor, pruebe con otro nombre.",
  [keys.ERR_noStripeCustomer]:
    "No es posible abrir las opciones de facturación hasta que su cuenta sea configurada. Por favor, póngase en contacto con nuestro equipo de soporte para continuar.",
  [keys.ERR_weAreSorry]: "Lo sentimos",
  [keys.ERR_internalIssuesTeamNotified]:
    "Parece que estamos teniendo algunos problemas internos. Nuestro equipo ha sido notificado. Si desea ayudar, puede describir lo que sucedió a continuación.",
  [keys.ERR_reloadPage]: "Recargue la página",
  [keys.ERR_letUsKnowWhatHappened]: "Cuéntenos qué ha sucedido.",
  [keys.ERR_failedToGetCountry]: "No se pudo obtener el país",
  [keys.ERR_errorLoadingPaymentNotification]:
    "Error al cargar PaymentNotification",
  [keys.ERR_userNotFound]:
    "No pudimos encontrar un usuario con esta dirección de correo electrónico. Por favor revise de nuevo. Si desea registrarse para obtener una nueva cuenta, comuníquese con su socio de integración o visite 360dialog.com",
  [keys.ERR_partnerNotPermitted]:
    "Lo sentimos, el socio dado no puede usar el Embedded Signup. Por favor contáctenos",
  [keys.ERR_inputFields]: "Error en los campos de entrada",
  [keys.ERR_fbConnectionError]:
    "Algo salió mal al intentar conectar tu cuenta de Meta.",
  [keys.ERR_fbNoRegisteredEmail]:
    "Su cuenta no tiene un correo electrónico registrado, agregue uno a su cuenta de Meta.",
  [keys.ERR_userNoExists]:
    "Este usuario no existe. Regístrese a través de la aplicación de su socio o 360dialog.com",
  [keys.ERR_addFundsNumber]:
    "No se pueden agregar fondos a los números de error",
  [keys.ERR_autoRenewalNumber]:
    "No se puede editar la configuración de renovación automática a números con errores",
  [keys.ERR_stripeErrorNote]:
    "Encontramos un error al cargar scripts de Stripe. Vuelve a cargar la página e inténtalo de nuevo.",
  [keys.ERR_filterOptionsErrorNote]:
    "Encontramos un error al cargar las opciones de filtro de idioma y zona horaria. Vuelve a cargar la página e inténtalo de nuevo.",
  //  [keys.ERR_publicDataErrorText]: "translation_missing",
  //  [keys.ERR_somethingUnexpected]: "translation_missing",
  //  [keys.ERR_phoneRegistrationError]: "translation_missing",
  //  [keys.ERR_userWithNoClientPermissionError]: "translation_missing",
};

export default translate;
