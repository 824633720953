// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  PRE_title: "PRE_title",
  PRE_display: "PRE_display",
  PRE_themeTitle: "PRE_themeTitle",
  PRE_themeSubtitle: "PRE_themeSubtitle",
  PRE_theme_light: "PRE_theme_light",
  PRE_theme_dark: "PRE_theme_dark",
  PRE_theme_system: "PRE_theme_system",
  PRE_behavior: "PRE_behavior",
  PRE_shortcutsTitle: "PRE_shortcutsTitle",
  PRE_shortcutsSubtitle: "PRE_shortcutsSubtitle",
  PRE_shortcutsCardTitle: "PRE_shortcutsCardTitle",
  PRE_showAll: "PRE_showAll",
  PRE_back: "PRE_back",
  PRE_nextTab: "PRE_nextTab",
  PRE_prevTab: "PRE_prevTab",
  PRE_nextPage: "PRE_nextPage",
  PRE_prevPage: "PRE_prevPage",
  PRE_focusSearch: "PRE_focusSearch",
  PRE_areaGeneral: "PRE_areaGeneral",
  PRE_areaNav: "PRE_areaNav",
  PRE_areaNavAdmin: "PRE_areaNavAdmin",
  PRE_areaNumbers: "PRE_areaNumbers",
  PRE_areaTemplates: "PRE_areaTemplates",
  PRE_areaPreferences: "PRE_areaPreferences",
  PRE_integrations: "PRE_integrations",
  PRE_partners: "PRE_partners",
  PRE_showHide: "PRE_showHide",
  PRE_openTemplateManager: "PRE_openTemplateManager",
  PRE_showDetails: "PRE_showDetails",
  PRE_newTemplate: "PRE_newTemplate",
  PRE_openPreview: "PRE_openPreview",
  PRE_duplicateTemplate: "PRE_duplicateTemplate",
  PRE_addLang: "PRE_addLang",
  PRE_themeToggle: "PRE_themeToggle",
  PRE_disableShortcuts: "PRE_disableShortcuts",
  PRE_langTitle: "PRE_langTitle",
  PRE_langSubtitle: "PRE_langSubtitle",
  PRE_loadingBillingPortal: "PRE_loadingBillingPortal",
  PRE_alertNoPayment: "PRE_alertNoPayment",
  PRE_alertNoPaymentLinkLabel: "PRE_alertNoPaymentLinkLabel",
  PRE_alertNoPaymentCountry: "PRE_alertNoPaymentCountry",
  PRE_alertNoPaymentCountryLinkLabe: "PRE_alertNoPaymentCountryLinkLabe",
  PRE_copyUrl: "PRE_copyUrl",
  PRE_otherAdmin: "PRE_otherAdmin",
  PRE_Privacy: "PRE_Privacy",
  PRE_preferencesTitle: "PRE_preferencesTitle",
  PRE_managePreferencesDescription: "PRE_managePreferencesDescription",
  PRE_cookiesChanged: "PRE_cookiesChanged",
  PRE_openPreferences: "PRE_openPreferences",
  PRE_cookies: "PRE_cookies",
  PRE_tos: "PRE_tos",
  PRE_openTerms: "PRE_openTerms",
  PRE_accepted: "PRE_accepted",
  PRE_notAccepted: "PRE_notAccepted",
  PRE_tosAcceptMsg: "PRE_tosAcceptMsg",
};

export default keys;
