// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.PRE_title]: "Preferences",
  [keys.PRE_display]: "Display",
  [keys.PRE_themeTitle]: "Theme",
  [keys.PRE_themeSubtitle]: "Select your interface color schema",
  [keys.PRE_theme_light]: "Light",
  [keys.PRE_theme_dark]: "Dark",
  [keys.PRE_theme_system]: "System preference",
  [keys.PRE_behavior]: "Behavior",
  [keys.PRE_shortcutsTitle]: "Enable keyboard shortcuts",
  [keys.PRE_shortcutsSubtitle]:
    "Type ? anywhere or click here for a list of all keyboard shortcuts",
  [keys.PRE_shortcutsCardTitle]: "Keyboard Shortcuts",
  [keys.PRE_showAll]: "Show all shortcuts",
  [keys.PRE_back]: "Back",
  [keys.PRE_nextTab]: "Next tab",
  [keys.PRE_prevTab]: "Previous tab",
  [keys.PRE_nextPage]: "Next page",
  [keys.PRE_prevPage]: "Previous page",
  [keys.PRE_focusSearch]: "Focus search field",
  [keys.PRE_areaGeneral]: "General",
  [keys.PRE_areaNav]: "Navigation",
  [keys.PRE_areaNavAdmin]: "Navigation (Admin)",
  [keys.PRE_areaNumbers]: "Number List",
  [keys.PRE_areaTemplates]: "Template Management",
  [keys.PRE_areaPreferences]: "Preferences",
  [keys.PRE_integrations]: "Integrations",
  [keys.PRE_partners]: "Partners",
  [keys.PRE_showHide]: "Show / hide detailed status",
  [keys.PRE_openTemplateManager]: "Open template manager",
  [keys.PRE_showDetails]: "Show details",
  [keys.PRE_newTemplate]: "New template",
  [keys.PRE_openPreview]: "Open preview",
  [keys.PRE_duplicateTemplate]: "Duplicate",
  [keys.PRE_addLang]: "Add language",
  [keys.PRE_themeToggle]: "Toggle theme",
  [keys.PRE_disableShortcuts]: "Disable shortcuts",
  [keys.PRE_langTitle]: "Language",
  [keys.PRE_langSubtitle]: "Select your preferred language",
  [keys.PRE_loadingBillingPortal]: "Loading billing portal",
  [keys.PRE_alertNoPayment]:
    "No payment method set. Please add a credit card in the <LINK>.",
  [keys.PRE_alertNoPaymentLinkLabel]: "billing options menu",
  [keys.PRE_alertNoPaymentCountry]:
    "In order to add funds to your template messaging balance you need to add your company’s tax ID on the billing options page.",
  [keys.PRE_alertNoPaymentCountryLinkLabe]: "Go to billing options menu",
  [keys.PRE_copyUrl]: "Copy current URL (with hub.360dialog.com base URL)",
  [keys.PRE_otherAdmin]: "Other (Admin)",
  [keys.PRE_Privacy]: "Privacy",
  [keys.PRE_preferencesTitle]: "Preferences",
  [keys.PRE_managePreferencesDescription]: "Manage preferences",
  [keys.PRE_cookiesChanged]: "Cookie settings have been changed successfully",
  [keys.PRE_openPreferences]: "Open preferences",
  [keys.PRE_cookies]: "Cookies",
  [keys.PRE_tos]: "Terms of Service",
  [keys.PRE_openTerms]: "Open Terms",
  [keys.PRE_accepted]: "Accepted",
  [keys.PRE_notAccepted]: "Not accepted",
  [keys.PRE_tosAcceptMsg]: "Terms of Service accepted.",
};

export default translate;
