// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.PRE_title]: "Einstellungen",
  [keys.PRE_display]: "Anzeige",
  [keys.PRE_themeTitle]: "Farbschema",
  [keys.PRE_themeSubtitle]: "Wähle dein Interface-Farbschema",
  [keys.PRE_theme_light]: "Hell",
  [keys.PRE_theme_dark]: "Dunkel",
  [keys.PRE_theme_system]: "Systempräferenz",
  [keys.PRE_behavior]: "Verhalten",
  [keys.PRE_shortcutsTitle]: "Aktivieren von Tastaturkürzeln",
  [keys.PRE_shortcutsSubtitle]:
    "Tippe an beliebiger Stelle ? oder klicke hier, um eine Liste aller Tastaturkürzel zu erhalten",
  [keys.PRE_shortcutsCardTitle]: "Tastaturkurzbefehle",
  [keys.PRE_showAll]: "Alle Kurzbefehle zeigen",
  [keys.PRE_back]: "Zurück",
  [keys.PRE_nextTab]: "Nächster Tab",
  [keys.PRE_prevTab]: "Vorheriger Tab",
  [keys.PRE_nextPage]: "Nächste Seite",
  [keys.PRE_prevPage]: "Vorherige Seite",
  [keys.PRE_focusSearch]: "Suchfeld fokussieren",
  [keys.PRE_areaGeneral]: "Allgemein",
  [keys.PRE_areaNav]: "Navigation",
  [keys.PRE_areaNavAdmin]: "Navigation (Admin)",
  [keys.PRE_areaNumbers]: "Nummerliste",
  [keys.PRE_areaTemplates]: "Vorlagen-Management",
  [keys.PRE_areaPreferences]: "Einstellungen",
  [keys.PRE_integrations]: "Integrationen",
  [keys.PRE_partners]: "Partner",
  [keys.PRE_showHide]: "Detaillierten Status anzeigen / verbergen",
  [keys.PRE_openTemplateManager]: "Vorlagen-Manager öffnen",
  [keys.PRE_showDetails]: "Details anzeigen",
  [keys.PRE_newTemplate]: "Neue Vorlage",
  [keys.PRE_openPreview]: "Vorschau öffnen",
  [keys.PRE_duplicateTemplate]: "Duplizieren",
  [keys.PRE_addLang]: "Sprache hinzufügen",
  [keys.PRE_themeToggle]: "Farbschema wechseln",
  [keys.PRE_disableShortcuts]: "Kurzbefehle deaktivieren",
  [keys.PRE_langTitle]: "Sprache",
  [keys.PRE_langSubtitle]: "Wähle deine favorisierte Sprache",
  [keys.PRE_loadingBillingPortal]: "Zahlungsportal wird geladen",
  [keys.PRE_alertNoPayment]:
    "Keine Zahlungsmethode vorhanden. Bitte füge eine Kreditkarte im <LINK> hinzu.",
  [keys.PRE_alertNoPaymentLinkLabel]: "Zahlungsportal",
  [keys.PRE_alertNoPaymentCountry]:
    "Um dein Konto mit Geld aufzuladen, musst du die Steuernummer deines Unternehmens unter den Zahlungsoptionen angeben",
  [keys.PRE_alertNoPaymentCountryLinkLabe]: "Gehe zu den Zahlungsoptionen",
  [keys.PRE_copyUrl]: "Aktuelle URL kopieren (mit Basis-URL hub.360dialog.com)",
  [keys.PRE_otherAdmin]: "Andere (Verwaltung)",
  //  [keys.PRE_Privacy]: "translation_missing",
  //  [keys.PRE_preferencesTitle]: "translation_missing",
  //  [keys.PRE_managePreferencesDescription]: "translation_missing",
  //  [keys.PRE_cookiesChanged]: "translation_missing",
  //  [keys.PRE_tos]: "translation_missing",
  //  [keys.PRE_openTerms]: "translation_missing",
  //  [keys.PRE_accepted]: "translation_missing",
  //  [keys.PRE_notAccepted]: "translation_missing",
  //  [keys.PRE_tosAcceptMsg]: "translation_missing",
};

export default translate;
