// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.WIZ_continueLabelWithHelp]: "To continue, finish the required steps",
  [keys.WIZ_billingPlanTitle]: "Select a plan",
  [keys.WIZ_billingMethodTitle]: "Add payment details",
  [keys.WIZ_billingTermsTitle]: "Accept terms & conditions",
  [keys.WIZ_paymentMethodTitleStep1]: "1. Select payment method",
  [keys.WIZ_paymentMethodCountrySelectLabel]: "2. Checkout",
  [keys.WIZ_paymentMethodCountrySelectLabel]:
    "Select your country to reveal available payment methods.",
  [keys.WIZ_termsTitle]: "360dialog's terms and conditions:",
  [keys.WIZ_numberWasSetUp]: "Your number <NUMBER> was successfully set up.",
  [keys.WIZ_connectInboxTitle]: "Finish your onboarding with get.chat",
  [keys.WIZ_connectInboxText]:
    "To set up your get.chat inbox, please follow the instructions from the email, which you should've received by now. Your WhatsApp Business Account is set up and already connected, so that you can immediately start using the inbox.",
  [keys.WIZ_goToHubButtonLabel]: "Continue to the 360dialog Client Hub",
  [keys.WIZ_channelPageCheckboxText1]: "👤 You have a personal Meta account.",
  [keys.WIZ_channelPageCheckboxText1]:
    "📞 The number, which you will be connecting is not currently in use with another WhatsApp Account.",
  [keys.WIZ_channelPageCheckboxText3]:
    "📲 You can verify your ownership of the number by receiving SMS or international phone calls.",
  [keys.WIZ_channelPageCheckboxText3]:
    "🖋️ You already have a Meta business account, which is verified.",
  [keys.WIZ_channelPageSubtitle1]:
    "In the next step you need to set up your accounts by using your Meta credentials and following the provided steps.",
  [keys.WIZ_channelPageSubtitle2]:
    "Afterwards we will automatically set everything up for you and you will be able to use the WhatsApp Business API.",
  [keys.WIZ_waitingText1]:
    "We’re currently setting up your own server instance, so you will be ready to start using the WhatsApp Business API in a few minutes.",
  [keys.WIZ_waitingText2]: "Starting your hosted WhatsApp Business API client.",
  [keys.WIZ_channelTitle]: "Make sure you fulfill the following requirements.",
  [keys.WIZ_titleSpecifiedPermission]:
    "The partner is requesting permission to access the WhatsApp Business API for the following number:",
  [keys.WIZ_titleUnspecifiedPermission]:
    "Select the numbers, for which you want to give permission to the partner to access the WhatsApp Business API.",
  [keys.WIZ_saveSelection]: "Save selection",
  [keys.WIZ_grantPermission]: "Grant permission",
  [keys.WIZ_optional]: "Optional",
  [keys.WIZ_successTitle]:
    "You’ve successfully connected your 360dialog account with",
  [keys.WIZ_successSubtitle1]:
    "You will return to <PARTNER> automatically, in 5 seconds.",
  [keys.WIZ_successSubtitle2]: "Until then, please do not close this window.",
  [keys.WIZ_successMainTitle]: "Partner application authorized!",
  [keys.WIZ_successTitleWithoutBrandName]:
    "You’ve just successfully connected your 360dialog account.",
  [keys.WIZ_successTitle1WithoutBrandName]:
    "You will return automatically, in 5 seconds.",
  [keys.WIZ_permissionsPageWarningMessage]:
    "Permission was requested for the number <NUMBER>, which does not exist on your 360dialog account. Do you want to add it now? Otherwise select a different number below.",
  [keys.WIZ_addThisNumber]: "Add this number",
  [keys.WIZ_addSelected]: "Add selected",
  [keys.WIZ_selectPlanTitle]: "Select your plan.",
  [keys.WIZ_selectPlanSubtitle]:
    "Just a few more steps until you can start chatting with your customers. Let’s start with the basics.",
  [keys.WIZ_addNumberText]:
    "Optionally, you can also <BUTTON>add a new number right now<BUTTON>.",
  [keys.WIZ_selectedCount]: "Selected <NUMBER> numbers",
  [keys.WIZ_selectedCountNewNumbers]: "Selected <NUMBER> new numbers",
  [keys.WIZ_welcomeToWABA]: "Welcome to the WhatsApp Business API!",
  [keys.WIZ_facebookAndWhatsAppBusAccount]:
    "Meta and WhatsApp Business Accounts",
  [keys.WIZ_accountsSuccessfullyCreated]: "Accounts successfully created!",
  [keys.WIZ_authorizePartnerApp]: "Authorize Partner Application",
  [keys.WIZ_numbers]: "Numbers",
  [keys.WIZ_selectNumbers]: "Select numbers",
  [keys.WIZ_setupFbAndWba]: "Set up Meta and WhatsApp Business Accounts",
  [keys.WIZ_nextSteps]:
    "In the next steps, you need to set up your accounts by using your Meta credentials and following the provided steps. You will also need to provide some payment information.",
  [keys.WIZ_companyInfoText]:
    "First, we need some information about your company.",
  [keys.WIZ_cardDetailsError]:
    "Payment error. Please check your card details and try again.",
  [keys.WIZ_insufficientFundsError]:
    "Payment error. Please check you have sufficient funds in the card and try again.",
  [keys.WIZ_tryAgain]: "Try again",
  [keys.WIZ_transactionCancelled]:
    "The transaction was refused. Please try again.",
  [keys.WIZ_transactionFailed]:
    "The transaction did not go through. Please try again.",
  [keys.WIZ_cardBlocked]:
    "The card used for the transaction is blocked, please introduce an active card and try again.",
  [keys.WIZ_cardExpired]:
    "The card used for the transaction is expired, please introduce an active card and try again.",
  [keys.WIZ_amountMismatch]:
    "An amount mismatch occurred during the transaction process. Please try again.",
  [keys.WIZ_cardInvalid]:
    "The specified card number is incorrect or invalid. Please introduce valid data and try again.",
  [keys.WIZ_paymentNotAllowed]:
    "Your bank does not support or does not allow this type of transaction. Please use a different payment method from another bank.",
  [keys.WIZ_cardLowBalance]:
    "The card does not have enough money to cover the payable amount. Add funds or introduce another card and try again.",
  [keys.WIZ_noPaymentMethod]:
    "No payment method set. Please add company details and credit card info",
  [keys.WIZ_cloudHostingIOInfo]:
    "Please note that in the Choose a WhatsApp Business account dropdown select only the Create a WhatsApp Business account option. Even if existing WhatsApp Business Account is selected by default please change it to Create a WhatsApp Business account and then Click Next. Otherwise the WhatsApp Number creation process will fail",
  [keys.WIZ_accountSetUpIssuesMessage]:
    "We couldn’t create your WhatsApp Account as we encountered Technical issues. As a Next Step, Please contact our Support for help",
  [keys.WIZ_accountCreationFailed]: "Account Creation Failled!",
  [keys.WIZ_updatePaymentDetails]: "Update payment details",
  [keys.WIZ_noPendingChannels]:
    "There are no channels pending approval for this client",
  [keys.WIZ_verifyOwnership]: "Verify ownership",
  [keys.WIZ_somethingWrong]:
    "Something went wrong? Contact your partner for clarification",
  [keys.WIZ_NumberVerificationTitle]: "Number ownership verification",
  [keys.WIZ_NumberVerificationDesc]:
    "Your partner {{partnerName}} wishes to grant you access to the phone numbers listed below. You will have the ability to manage them and gain insights through our 360dialog Client Hub. Please verify the ownership of the numbers.",
  [keys.WIZ_success]: "Success!",
  [keys.WIZ_successMessage]:
    "You’ve successfully activated your numbers. Your integration partner {{partnerName}} will be informed, so that you can start using their services with WhatsApp shortly. You can safely close this window.",
  [keys.WIZ_paymentMethodError]:
    "Error updating payment method. Check your details and try again.",
};

export default translate;
