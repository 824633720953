// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.NAV_whatsappAccounts]: "WhatsApp Accounts",
  [keys.NAV_partners]: "Partners",
  [keys.NAV_integrations]: "Integrations",
  [keys.NAV_support]: "Support",
  [keys.NAV_profile]: "Profile",
  [keys.NAV_billing]: "Billing Options",
  [keys.NAV_helpAndFeedback]: "Help and Feedback",
  [keys.NAV_preferences]: "Preferences",
  [keys.NAV_signOut]: "Sign out",
  [keys.NAV_dock]: "Dock Navigation",
  [keys.NAV_undock]: "Undock Navigation",
  [keys.NAV_templateBilling]: "Template Billing",
  [keys.NAV_conversationBilling]: "Conversation Billing",
  [keys.NAV_billingText]: "Billing",
  [keys.NAV_insights]: "Insights",
  [keys.NAV_insightsAndBilling]: "Insights & Billing",
  [keys.NAV_organizationSettings]: "Organization settings",
  [keys.NAV_connectedApps]: "Connected Apps",
  [keys.NAV_notifications]: "Notifications",
  [keys.NAV_logout]: "Logout",
  [keys.NAV_gettingStarted]: "Getting started",
  [keys.NAV_roadmap]: "Roadmap",
  [keys.NAV_billinOptions]: "Billing Options",
  [keys.NAV_company]: "Company",
  [keys.NAV_adAccounts]: "Ad Accounts",
  [keys.NAV_pageUnavailable]: "Page unavailable for your account type.",
  [keys.NAV_team]: "Team",
  [keys.NAV_settings]: "Settings",
};

export default translate;
