import * as Types from "./accounting.types";

export const unpackStripePortal = (
  response: Types.IServerResponseStripePortal
): Types.IUIResponseStripePortal => ({
  url: response.url,
  livemode: response.livemode,
});

export const mapUsage = (
  usage: Array<Types.IServerUsageItem>
): Array<Types.IUIUsageItem> =>
  usage.map(
    (item: Types.IServerUsageItem): Types.IUIUsageItem => ({
      appId: item.app_id,
      periodDate: item.period_date,
      quantity: item.quantity,
      userInitiatedQuantity: item.user_initiated_quantity,
      businessInitiatedQuantity: item.business_initiated_quantity,
      freeQuantity: item.free_quantity,
      freeTier: item.free_tier,
      freeEntryPoint: item.free_entry_point,
      paidQuantity: item.paid_quantity,
      userInitiatedPaidQuantity: item.user_initiated_paid_quantity,
      businessInitiatedPaidQuantity: item.business_initiated_paid_quantity,
      totalPrice: item.total_price,
      userInitiatedPrice: item.user_initiated_price,
      businessInitiatedPrice: item.business_initiated_price,
      marketingQuantity: item.marketing_quantity,
      marketingPaidQuantity: item.marketing_paid_quantity,
      marketingPrice: item.marketing_price,
      serviceQuantity: item.service_quantity,
      servicePaidQuantity: item.service_paid_quantity,
      servicePrice: item.service_price,
      authenticationQuantity: item.authentication_quantity,
      authenticationPaidQuantity: item.authentication_paid_quantity,
      authenticationPrice: item.authentication_price,
      utilityQuantity: item.utility_quantity,
      utilityPaidQuantity: item.utility_paid_quantity,
      utilityPrice: item.utility_price,
    })
  );

export const unpackClientBalance = (
  response: Types.IServerResponseClientBalance
): Types.IUIResponseClientBalance => ({
  channelId: response.channel_id,
  balance: response.balance,
  estimatedTemplateCost: response.estimated_template_cost,
  userInitiatedCost: response.ui_price_for_currency_and_client_country,
  businessInitiatedCost: response.bi_price_for_currency_and_client_country,
  lastRenewal: {
    amount: response.last_renewal?.amount,
    date: response.last_renewal?.date,
  },
  usage: response.usage.length ? mapUsage(response.usage) : [],
  currency: response.currency,
});

export const unpackPartnerUsage = (
  response: Types.IServerResponsePartnerUsage
): Types.IUIResponsePartnerUsage => ({
  usage: response.usage.length ? mapUsage(response.usage) : [],
  currency: response.currency,
});

export const unpackCost = (
  response: Types.IServerResponseCostCalculations
): Types.IUIResponseCostCalculations => {
  let taxValue = 0,
    taxName = "N/A",
    taxPercent = 0;
  if (response.details?.items) {
    taxValue =
      response.details.items[0].tax_value + response.details.items[1].tax_value;
    taxName = response.details.items[0].tax_name;
    taxPercent = response.details.items[0].tax_percentage;
  }
  return {
    costWithFees: response.amount_due,
    costBeforeFees: response.balance_to_add,
    taxValue: taxValue,
    transactionFees: response.amount_due - response.balance_to_add - taxValue,
    taxName: taxName,
    taxPercent: taxPercent,
  };
};

export const unpackCostPbs = (
  response: Types.IServerResponseCostCalculationsPbs
): Types.IUIResponseCostCalculations => {
  if (!response) {
    return null;
  }

  const taxName = "N/A";
  const taxValue = response.taxAmount;
  const taxPercent = response.amount
    ? Math.floor((response.taxAmount / response.amount) * 100)
    : 0;
  const costWithFees = parseFloat(
    (response.amount + response.taxAmount + response.transactionFee)?.toFixed(2)
  );

  return {
    costWithFees,
    costBeforeFees: response.amount,
    taxValue,
    transactionFees: response.transactionFee,
    taxName,
    taxPercent,
  };
};

export const unpackAutoRecharge = (
  response: Types.IServerResponseAutoRecharge
): Types.IUIResponseAutoRecharge => ({
  autoUpdateRechargeSettings: response.auto_update_recharge_settings,
  autoRechargeEnabled: response.auto_recharge_enabled,
  rechargeThreshold: response.recharge_balance_threshold,
  rechargeAmount: response.recharge_top_up_amount,
  channelId: response.id,
  clientId: response.client_id,
  createdAt: response.created_at,
  createdBy: {
    userId: response.created_by.user_id,
    userName: response.created_by.user_name,
  },
  modifiedBy: {
    userId: response.modified_by.user_id,
    userName: response.modified_by.user_name,
  },
  modifiedAt: response.modified_at,
  currentBalance: response.current_balance,
  partnerId: response.partner_id,
});

// This mapper can be merged with unpackAutoRecharge above if we finalize benchamrking that all the instances that are using it work different for PBS and stripe.

export const unpackPbsAutoRecharge = (response) => ({
  autoRechargeEnabled: response.autoRechargeEnabled,
  rechargeThreshold: response.rechargeBalanceThreshold,
  rechargeAmount: response.rechargeAmount,
  channelId: response.hubChannelId,
  clientId: response.hubClientId,
  currentBalance: response.currentBalance,
  partnerId: response.hubPartnerId,
});

export const packAutoRechargeData = (
  request: Types.IUIResponseAutoRecharge
): Types.IRequestAutoRecharge => {
  return {
    auto_update_recharge_settings: request.autoUpdateRechargeSettings,
    auto_recharge_enabled: request.autoRechargeEnabled,
    recharge_balance_threshold: request.rechargeThreshold || 0,
    recharge_top_up_amount: request.rechargeAmount || 0,
  };
};

export const unpackFundsResponse = (
  resp: Types.IResponseFunds,
  billingSystem: string
) => {
  if (billingSystem === "stripe") {
    return {
      status: resp.stripe_metadata.status,
      type: resp.stripe_metadata.next_action?.type,
      url: resp.stripe_metadata.next_action?.redirect_to_url.url,
      returnUrl: resp.stripe_metadata.next_action?.redirect_to_url.return_url,
    };
  } else if (billingSystem === "pbs") {
    return {
      action: resp.action,
      currency: resp.currency,
      total: resp.balance,
      status: resp.status,
      paymentReference: resp.paymentReference,
    };
  }
};
