// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.PRE_title]: "Preferencias",
  [keys.PRE_display]: "Display",
  [keys.PRE_themeTitle]: "Tema",
  [keys.PRE_themeSubtitle]: "Seleccione el esquema de color de su interfaz",
  [keys.PRE_theme_light]: "Claro",
  [keys.PRE_theme_dark]: "Oscuro",
  [keys.PRE_theme_system]: "Preferencias de sistema",
  [keys.PRE_behavior]: "Comportamiento",
  [keys.PRE_shortcutsTitle]: "Permitir atajos de teclado",
  [keys.PRE_shortcutsSubtitle]:
    "Escriba ? en cualquier lugar o haga click aquí para obtener una lista de todos los atajos de teclado",
  [keys.PRE_shortcutsCardTitle]: "Atajos de teclado",
  [keys.PRE_showAll]: "Muestra todos los atajos",
  [keys.PRE_back]: "Volver",
  [keys.PRE_nextTab]: "Siguiente pestaña",
  [keys.PRE_prevTab]: "Pestaña anterior",
  [keys.PRE_nextPage]: "Página siguiente",
  [keys.PRE_prevPage]: "Pagina anterior",
  [keys.PRE_focusSearch]: "Campo de búsqueda",
  [keys.PRE_areaGeneral]: "General",
  [keys.PRE_areaNav]: "Navegación",
  [keys.PRE_areaNavAdmin]: "Navegación (Admin)",
  [keys.PRE_areaNumbers]: "Lista de números",
  [keys.PRE_areaTemplates]: "Administrador de Templates",
  [keys.PRE_areaPreferences]: "Preferencias",
  [keys.PRE_integrations]: "Integración",
  [keys.PRE_partners]: "Partners",
  [keys.PRE_showHide]: "Mostrar / ocultar estado detallado",
  [keys.PRE_openTemplateManager]: "Abrir manager de templates",
  [keys.PRE_showDetails]: "Mostrar detalles",
  [keys.PRE_newTemplate]: "Nueva template",
  [keys.PRE_openPreview]: "Vista previa",
  [keys.PRE_duplicateTemplate]: "Duplicar",
  [keys.PRE_addLang]: "Agregar lenguaje",
  [keys.PRE_themeToggle]: "Cambiar tema",
  [keys.PRE_disableShortcuts]: "Deshabilitar atajos",
  [keys.PRE_langTitle]: "Idioma",
  [keys.PRE_langSubtitle]: "Seleccione su idioma de preferencia",
  [keys.PRE_loadingBillingPortal]: "Cargando portal de facturación",
  [keys.PRE_alertNoPayment]:
    "Ningún método de pago configurado. Por favor, añada una tarjeta de crédito en el <LINK>.",
  [keys.PRE_alertNoPaymentLinkLabel]: "Menú de opciones de pago",
  [keys.PRE_alertNoPaymentCountry]:
    "Para añadir fondos al saldo de su template de mensajería, debe añadir el número de identificación fiscal de su empresa en la página de opciones de facturación.",
  [keys.PRE_alertNoPaymentCountryLinkLabe]:
    "Ir al menú de opciones de facturación",
  [keys.PRE_copyUrl]: "Copiar URL corriente (con URL base hub.360dialog.com)",
  [keys.PRE_otherAdmin]: "Otro (Admin)",
  //  [keys.PRE_Privacy]: "translation_missing",
  //  [keys.PRE_preferencesTitle]: "translation_missing",
  //  [keys.PRE_managePreferencesDescription]: "translation_missing",
  //  [keys.PRE_cookiesChanged]: "translation_missing",
  //  [keys.PRE_tos]: "translation_missing",
  //  [keys.PRE_openTerms]: "translation_missing",
  //  [keys.PRE_accepted]: "translation_missing",
  //  [keys.PRE_notAccepted]: "translation_missing",
  //  [keys.PRE_tosAcceptMsg]: "translation_missing",
};

export default translate;
