// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.A_SomethingWentWrongError]: "Etwas ist schief gelaufen",
  [keys.A_SelectDefaultPlaceholder]: "Wähle aus",
  [keys.A_phoneNumber]: "Telefonnummer",
  [keys.A_waba]: "WhatsApp Business Account",
  [keys.A_client]: "Kunde",
  [keys.A_partner]: "Partner",
  [keys.A_documentation]: "Dokumentation",
  [keys.A_email]: "E-Mail",
  [keys.A_password]: "Passwort",
  [keys.A_back]: "Zurück",
  [keys.A_sortBy]: "Sortieren nach",
  [keys.A_date]: "Datum",
  [keys.A_status]: "Status",
  [keys.A_displayName]: "Display Name",
  [keys.A_filterByStatus]: "Nach Status filtern",
  [keys.A_searchHere]: "Suche",
  [keys.A_account]: "Account  ",
  [keys.A_accountName]: "Account Name",
  [keys.A_search]: "Suche",
  [keys.A_cancel]: "Abbrechen",
  [keys.A_continue]: "Fortfahren",
  [keys.A_close]: "Schließen",
  [keys.A_delete]: "Löschen",
  [keys.A_edit]: "Editieren",
  [keys.A_and]: "und",
  [keys.A_name]: "Name",
  [keys.A_deletePlaceholder]: "Tippe <DELETE> zur Bestätigung",
  [keys.A_deleteHelptextDefault]:
    "Diese Aktion kann nicht widerrufen werden. Bist du sicher, dass du fortfahren möchtest?",
  [keys.A_viewDocs]: "Dokumentation ansehen",
  [keys.A_defaultLanguage]: "Standard-Spracheinstellung",
  [keys.A_save]: "Speichern",
  [keys.A_learnMore]: "Mehr erfahren",
  // [keys.A_audiencesLearnMore]: "translation_missing",
  [keys.A_address]: "Adresse",
  [keys.A_description]: "Beschreibung",
  [keys.A_website]: "Website",
  [keys.A_deleteImage]: "Bild löschen",
  [keys.A_dragYourImage]: "Ziehe dein Bild hier her oder",
  [keys.A_browse]: "wähle eines",
  [keys.A_supportsFormats]: "Unterstützt jpg, png und svg",
  [keys.A_upload]: "Hochladen",
  [keys.A_imageMaxSize]: "Das Bild sollte weniger als 3MB haben",
  [keys.A_paymentMethodCountrySelectLabel]:
    "Wähle dein Land aus, um die verfügbaren Zahlungsarten anzuzeigen.",
  [keys.A_intCreditCard]: "Internationale Kreditkarte",
  [keys.A_paymentMethodSelectLabel]: "Wähle deine bevorzugte Zahlungsmethode",
  [keys.A_savePaymentMethod]: "Zahlungsmethode speichern",
  [keys.A_country]: "Land",
  [keys.A_cardExistingText]: "Du hast bereits eine Kreditkarte eingereicht.",
  [keys.A_addAnotherCardButton]: "Eine weitere Kreditkarte hinzufügen",
  [keys.A_creditCardFormHelptext]:
    "Bitte gib unten deine Kreditkarteninformationen ein.",
  [keys.A_submitCardButton]: "Kreditkarte einreichen",
  [keys.A_termsStep1]: "Ich akzeptiere die",
  [keys.A_termsOfService]: "Servicebedingungen",
  [keys.A_termsConnect]: "sowie",
  [keys.A_terms360exhibit1]: "Anhang 1 - 360dialog-Nutzungsbedingungen",
  [keys.A_terms360exhibit3]: "Anhang 3 - 360dialog Preisgestaltung",
  [keys.A_waTermsStep1]:
    "Ich habe zur Kenntnis genommen und stimme folgendem zu",
  [keys.A_terms360exhibit2]: "Anhang 2 - Meta Client-Bedingungen für WhatsApp",
  [keys.A_partnerTermsConnect]: "sowie den",
  [keys.A_privacyPolicy]: "Datenschutzbestimmungen",
  [keys.A_partnersTermsAndConditions]: "Geschäftsbedingungen",
  [keys.A_selectNumberTitle]: "Nummer auswählen",
  [keys.A_addNewNumber]: "Neue Nummer hinzufügen",
  [keys.A_or]: "oder",
  [keys.A_tooltipEmbeddedSignup]:
    "Übermittlungen mit diesem Formular folgen dem Aktivierungsablauf für eingebettete Anmeldungen. Aktiviere Konten in weniger als 10 Minuten, und schließe den Verifizierungsprozess später ab. <LINK>https://docs.360dialog.com/embedded-signup | Mehr erfahren.<LINK>",
  [keys.A_tooltipClassicSignup]:
    "Einreichungen mit diesem Formular folgen dem klassischen Aktivierungsablauf. Unternehmen müssen vor dem Zugriff auf die API vollständig verifiziert werden. <LINK>https://docs.360dialog.com/submission-process | Mehr erfahren.<LINK>",
  [keys.A_loading]: "Lädt",
  [keys.A_notImage]:
    "Die hochgeladene Datei sollte im Format jpg, png oder svg sein.",
  [keys.A_tooltipNumberPortingSignup]:
    "Mit diesem Formular können Kunden durch Portierung einer Nummer von einem anderen BSP ein Konto einrichten. <LINK>https://docs.360dialog.com/360-client-hub/number-porting | Mehr erfahren.<LINK>",
  [keys.A_FbmId]: "Business Manager ID",
  [keys.A_buttonLabelReturnToHub]: "Zum 360dialog Client Hub zurückkehren",
  [keys.A_notificationNoPermission]:
    "Du hast keine Berechtigung, auf diese Seite zuzugreifen, da dieses Konto mit einem anderen Partner verbunden ist. Wenn du deine Nummern zu diesem Partner migrieren möchtest, beantrage bitte einen Partnerwechsel in deinem Profil -> Organisationseinstellungen über hub.360dialog.com.",
  [keys.A_textNoPermission]:
    "Du hast keine Berechtigung, auf diese Seite zuzugreifen, weil dieses Konto mit einem anderen Partner verbunden ist.",
  [keys.A_continueNext]: "Weiter zum nächsten Schritt",
  [keys.A_pleaseTryAgain]:
    "Wir haben derzeit Probleme mit dem Login. Bitte versuche es in 30 Minuten erneut.",
  [keys.A_clientsSearchPlaceholder]: "Suche nach Name",
  [keys.A_terms]: "Bedingungen",
  [keys.A_userNotExists]:
    "Dieser Benutzer existiert nicht. Bitte melde dich über die App deines Partners oder 360dialog.com an.",
  [keys.A_verificationError]:
    "Ein Fehler ist aufgetreten, bitte versuche es erneut",
  [keys.A_partnerIsBlocked]: "Partner ist für neue Anmeldungen gesperrt",
  [keys.A_urlCopiedToClipboard]: "URL in die Zwischenablage kopiert",
  [keys.A_latestSearch]: "Letzte Suche",
  [keys.A_loadingBillingPortal]: "Abrechnungsportal wird geladen",
  [keys.NOT_allNotifications]: "Alle",
  [keys.NOT_archivedNotifications]: "Archiviert",
  [keys.NOT_closeButtonTooltip]: "Diese Benachrichtigung archivieren",
  [keys.NOT_archiveAllBtn]: "Alle archivieren",
  [keys.NOT_clientAdded]:
    "Der neue Kunde wird zu deinem Partner-Hub hinzugefügt.",
  [keys.A_errorDownloadingCSV]:
    "Beim Versuch, die CSV-Datei herunterzuladen, ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.",
  [keys.A_financialReportSentToEmail]:
    "Der Finanzbericht wird in Kürze an deine E-Mail Adresse gesendet.",
  [keys.A_errorGeneratingFinancialReport]:
    "Bei der Erstellung des Finanzberichts ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.",
  [keys.A_exportAllContactData]: "Alle Kontaktdaten exportieren",
  [keys.A_generateFinancialReport]: "Finanzbericht generieren",
  [keys.A_generateInvoices]: "Rechnungen generieren",
  [keys.A_templateMessages]: "Template-Nachrichten",
  [keys.A_generateTemplateInvoices]: "Rechnungen für Templates generieren",
  [keys.A_generateTemplateReport]: "Templates Bericht generieren",
  [keys.A_conversationBasedPricing]: "Gesprächsbezogene Preisgestaltung",
  [keys.A_generateCBPInvoices]: "CBP-Rechnungen generieren",
  [keys.A_generateCBPDailyReport]: "CBP-Tagesbericht generieren",
  [keys.A_generateCBPMonthlyReport]: "CBP-Monatsbericht generieren",
  [keys.A_invoiceCreationTriggered]:
    "Rechnungserstellung ausgelöst. Die Rechnungen werden per E-Mail versandt.",
  [keys.A_invoiceDateNeedsToBeFirstOrLast]:
    "Das Rechnungsdatum muss der erste oder letzte Tag eines Monats sein.",
  [keys.A_dateOfInvoice]: "Datum der Rechnung",
  [keys.A_selectInvoicingDate]: "Wähle das Datum der Rechnungsstellung",
  [keys.A_reportCreationTriggered]:
    "Berichtserstellung ausgelöst. Der Bericht wird per E-Mail versendet.",
  [keys.A_triggerInvoiceCreation]: "Rechnungserstellung auslösen",
  [keys.A_selectReportDate]: "Berichtsdatum wählen",
  [keys.A_dateOfReport]: "Datum des Berichts",
  [keys.A_monthlyReportCreationTriggered]:
    "Monatliche Berichtserstellung ausgelöst. Der Bericht wird per E-Mail versandt.",
  [keys.A_dailyReportCreationTriggered]:
    "Tägliche Berichtserstellung ausgelöst. Der Bericht wird per E-Mail verschickt.",
  [keys.A_selectMonthlyReportDate]: "Wähle monatliches Berichtsdatum",
  [keys.A_selectDailyReportDate]: "Wähle das Datum des Tagesberichts",
  [keys.A_somethingWentWrongnTryAgain]:
    "Ein Fehler ist aufgetreten, bitte versuche es später noch einmal",
  [keys.A_linkURL]: "Link-URL",
  [keys.A_parameters]: "Parameter",
  [keys.A_imageRecommendation]:
    "Empfehlung: maximale Größe von 5 MB und Bildgröße von 640x640.",
  [keys.A_displayLogoAndDeepLinkNotice]:
    "Auf diese Weise sehen Kunden dein Logo und deinen Markennamen an mehreren Stellen innerhalb des 360dialog Client Hub.",
  [keys.A_attentionToShowLogoAndBrandSet]:
    "Das folgende Element wird nur angezeigt wenn sowohl das Firmenlogo als auch der Markenname gesetzt sind.",
  [keys.A_attentionToShowIfURLProvided]:
    "Das folgende Element wird nur angezeigt wenn eine Deeplink-URL angegeben wurde.",
  [keys.A_companyLogo]: "Firmenlogo",
  [keys.A_uploadLogo]: "Logo hochladen",
  [keys.A_profilePreview]: "Profil-Vorschau",
  [keys.A_deeplinkURL]: "Deeplink-URL",
  [keys.A_logoChangeSuccess]: "Logo erfolgreich geändert",
  [keys.A_deeplinkURLToolTipContent]:
    "Wenn dieser Wert gesetzt ist, haben Nutzer die Möglichkeit, nach der Erstellung ihres API-Schlüssels auf eine Schaltfläche zu klicken, die sie zu deiner Anwendung zurückführt, wo sie den API-Schlüssel platzieren können.",
  [keys.A_logoAndBrandNameVariantOne]: "Variante 1: Migrationsformular",
  [keys.A_logoAndBrandNameVariantTwo]: "Variante 2: Logo und Markenname",
  [keys.A_featured]: "Ausgewählte",
  [keys.A_verifyHuman]: "Überprüfung, dass du ein Mensch bist",
  [keys.A_insightsAndFunds]: "Insights & Funds",
  [keys.A_letsDoIt]: "Los geht's!",
  [keys.A_paymentPlan]: "Wähle deinen Tarif",
  [keys.A_newNumberPlan]: "Wähle den Tarif für die neue Nummer",
  [keys.A_betterSla]: "Bessere SLA",
  [keys.A_higherScalability]: "Höhere Skalierbarkeit",
  [keys.A_verifyBusiness]: "Verifiziere dein Unternehmen!",
  [keys.A_verifyFBM]:
    "Gehe zum Meta Business Manager, um mit dem Prozess der Unternehmensverifizierung zu beginnen.",
  [keys.A_accessCompanyFb]:
    "Um fortzufahren, musst du <BOLD>auf das Meta-Geschäftskonto deines Unternehmens zugreifen.<BOLD>",
  [keys.A_continueWithFbButton]:
    'Durch Klicken auf die Schaltfläche "Mit Meta fortfahren" weiter unten kannst du:',
  [keys.A_addNumbers]: "Nummern hinzufügen",
  [keys.A_addNumber]: "Nummer hinzufügen",
  [keys.A_addFirstNumber]:
    "Du hast noch keine Telefonnummern hinzugefügt. Füge deine erste Nummer hinzu, um mit der WhatsApp Business Platform zu beginnen.",
  [keys.A_termsAndConditions]: "Geschäftsbedingungen",
  [keys.A_paymentDetails]: "Details zur Bezahlung",
  [keys.A_facebookSetup]: "Facebook-Einrichtung",
  [keys.A_termsAndPriceList1]:
    "Ich stimme den <LINK>https://www.360dialog.com/contact#privacy-policy | 360dialog Nutzungsbedingungen<LINK>, sowie der",
  [keys.A_termsAndPriceList2]:
    "<LINK>https://www.360dialog.com/whatsapp-business-api?origin=new_hub#pricing | 360dialog Preisliste<LINK> zu.",
  [keys.A_user]: "Benutzer",
  [keys.A_insightsPageTitle]: "360dialog - Insights",
  [keys.A_insightsPageDescription]: "Insights & Funds ansehen",
  [keys.A_supportPageTitle]: "360dialog - Support",
  [keys.A_supportPageDescription]: "Support und Kontakt",
  [keys.A_detailsPageTitle]: "360dialog - Kanal-Details",
  [keys.A_detailsPageDescription]: "Details zu einem Kanal anzeigen",
  [keys.A_changeDisplayName]: "Anzeigename ändern",
  [keys.A_pleaseContinueRegistration]:
    "Bitte setze die Registrierung deiner Nummer fort",
  [keys.A_continueRegistration]: "Registrierung fortsetzen",
  [keys.A_continueVerification]: "Verifizierung fortsetzen",
  [keys.A_numbers]: "Nummern",
  [keys.A_inappropriatePassword]:
    "Benutzer kann nicht erstellt werden. Grund: Das Passwort enthält Benutzerinformationen",
  [keys.A_businessVerifiedButton]:
    "Ich habe mein Unternehmen bereits verifiziert",
  [keys.A_businessNotVerifiedButton]: "Ohne Verifizierung fortfahren",
  [keys.A_chatSupportEnabled]: "Chat-Support aktiviert",
  [keys.A_acceptAndClose]: "Akzeptieren und schließen",
  [keys.A_fullInfoDetails]:
    "Die vollständigen Informationen findest du {{hereLink}}",
  [keys.A_here]: "hier",
  [keys.A_subProcessorPopupTitle]:
    "Informationen über die Aufnahme neuer Sub-Processors",
  [keys.A_thatsAllNotifications]:
    "Das sind alle deine Benachrichtigungen der letzten 30 Tage.",
  [keys.A_errorPaymentToken]: "Fehler beim Abrufen des Zahlungstokens",
  [keys.A_maintenance]:
    "Wir führen gerade Wartungsarbeiten durch und sind in Kürze wieder da. Wir entschuldigen uns für die Unannehmlichkeiten. Um mehr zu erfahren, besuche bitte unsere <LINK>https://status.360dialog.com | Statusseite.<LINK>",
  [keys.A_userMaintenance]: "Benutzer-Wartung",
  [keys.A_actions]: "Aktionen",
  [keys.A_signals]: "Signale",
  [keys.A_manage]: "Verwalten",
  [keys.A_qualityRating]: "Quality rating",
  [keys.A_variantIOTitle]: "Variante 2: Integriertes Onboarding",
  [keys.A_allowClientsAddNumber]:
    "Kunden das Hinzufügen von Telefonnummern gestatten",
  [keys.A_allowClientsAddNumberTooltip]:
    "Schränkt das Hinzufügen von Telefonnummern durch die Kunden ein oder erlaubt es ihnen. Dies gilt nur für das Hinzufügen von Nummern über den 360dialog Client Hub. Es gilt nicht für das integrierte Onboarding.",
  [keys.A_allow]: "Erlauben",
  [keys.A_restrict]: "Einschränken",
  [keys.A_restrictClientMessage]:
    "Möchtest du deine Kunden daran hindern, selbst Telefonnummern im 360dialog Client Hub hinzuzufügen?",
  [keys.A_allowClientMessage]:
    "Möchtest du deinen Kunden erlauben, selbst Telefonnummern innerhalb des 360dialog Client Hub hinzuzufügen?",
  [keys.A_updatedAllowedMessage]:
    "Aktualisiert, Kunden können ihre Telefonnummern nicht mehr hinzufügen.",
  [keys.A_updatedRestrictMessage]:
    "Aktualisiert, Kunden können deine Telefonnummern nicht mehr hinzufügen.",
  [keys.A_infoDetailsPriceChange]:
    "Bitte klicke auf {{hereLink}}, um die Änderungen auf einer eigenen Seite zu sehen.",
  [keys.A_priceChangePopupTitle]: "Informationen zu Preisänderungen",
  //  [keys.A_selectEventType]: "translation_missing",
  //  [keys.A_whereWasEvent]: "translation_missing",
  //  [keys.A_eventWithinConversation]: "translation_missing",
  //  [keys.A_eventOutsideConversation]: "translation_missing",
  //  [keys.A_messagingEvent]: "translation_missing",
  //  [keys.A_externalEvent]: "translation_missing",
  //  [keys.A_enterNumber]: "translation_missing",
  //  [keys.A_yourNumber]: "translation_missing",
  //  [keys.A_iHaveNumber]: "translation_missing",
  //  [keys.A_iDontHaveNumber]: "translation_missing",
  //  [keys.A_selectDoYouHaveNumber]: "translation_missing",
  //  [keys.A_doYouHaveNumber]: "translation_missing",
  //  [keys.A_youNeedNumberToFinalizeJourney]: "translation_missing",
  //  [keys.A_createNumber]: "translation_missing",
  //  [keys.A_basicSubPlan]: "translation_missing",
  //  [keys.A_regularSubPlan]: "translation_missing",
  //  [keys.A_premiumSubPlan]: "translation_missing",
  //  [keys.A_cloudAPIHosting]: "translation_missing",
  //  [keys.A_HostingService]: "translation_missing",
  //  [keys.A_TicketBasedSupport]: "translation_missing",
  //  [keys.A_OnPremiseHosting]: "translation_missing",
  //  [keys.A_RTChat]: "translation_missing",
  //  [keys.A_WABAReq]: "translation_missing",
  //  [keys.A_AdsFunnelTracking]: "translation_missing",
  //  [keys.A_recommended]: "translation_missing",
  //  [keys.A_choosePlan]: "translation_missing",
  //  [keys.A_SelectPlan]: "translation_missing",
  //  [keys.A_includes]: "translation_missing",
  //  [keys.A_prevTierPlus]: "translation_missing",
  //  [keys.A_continueWithSelectedPlan]: "translation_missing",
  //  [keys.A_activityPageDescription]: "translation_missing",
  //  [keys.A_activityPageTitle]: "translation_missing",
  //  [keys.A_activity]: "translation_missing",
  //  [keys.A_noActivityFound]: "translation_missing",
  //  [keys.A_noActivitySeen]: "translation_missing",
  //  [keys.A_allChannels]: "translation_missing",
  //  [keys.A_addNumberToSeeActivity]: "translation_missing",
  //  [keys.A_addNumberOverview]: "translation_missing",
  //  [keys.A_funnels]: "translation_missing",
  //  [keys.A_expenseTooltip]: "translation_missing",
  //  [keys.A_downloadContacts]: "translation_missing",
  //  [keys.A_downloadContactsList1]: "translation_missing",
  //  [keys.A_downloadContactsList2]: "translation_missing",
  //  [keys.A_couldYouTellUsMore]: "translation_missing",
  //  [keys.A_downloadCsv]: "translation_missing",
  //  [keys.A_nameTheSource]: "translation_missing",
  //  [keys.A_nameTheSourceDescription]: "translation_missing",
  //  [keys.A_sourceName]: "translation_missing",
  //  [keys.A_message]: "translation_missing",
  //  [keys.A_saveSource]: "translation_missing",
  //  [keys.A_inviteMarketer]: "translation_missing",
  //  [keys.A_inviteMarketer1]: "translation_missing",
  //  [keys.A_coworkerEmail]: "translation_missing",
  //  [keys.A_invite]: "translation_missing",
  //  [keys.A_anInviteWasSent]: "translation_missing",
  //  [keys.A_messageDetails]: "translation_missing",
  //  [keys.A_checkSelectedMessages]: "translation_missing",
  //  [keys.A_sourceInvestment]: "translation_missing",
  //  [keys.A_investmentMade]: "translation_missing",
  //  [keys.A_amountCurrency]: "translation_missing",
  //  [keys.A_investedAmount]: "translation_missing",
  //  [keys.A_saveInvestment]: "translation_missing",
  //  [keys.A_requestPartnerAccess]: "translation_missing",
  //  [keys.A_trackEvent]: "translation_missing",
  //  [keys.A_selectPoolConversations]: "translation_missing",
  //  [keys.A_billingPageTitle]: "translation_missing",
  //  [keys.A_billingPageDescription]: "translation_missing",
  //  [keys.A_coworkerName]: "translation_missing",
  //  [keys.A_sentTo]: "translation_missing",
  //  [keys.A_viewConversationsInsights]: "translation_missing",
  //  [keys.A_insightsPageTitle]: "translation_missing",
  //  [keys.A_insightsPageDescription]: "translation_missing",
  //  [keys.A_measurePerformance]: "translation_missing",
  //  [keys.A_selectEventType]: "translation_missing",
  //  [keys.A_whereWasEvent]: "translation_missing",
  //  [keys.A_eventWithinConversation]: "translation_missing",
  //  [keys.A_eventOutsideConversation]: "translation_missing",
  //  [keys.A_messagingEvent]: "translation_missing",
  //  [keys.A_externalEvent]: "translation_missing",
  //  [keys.A_changeEventType]: "translation_missing",
  //  [keys.A_adAccountsPageTitle]: "translation_missing",
  //  [keys.A_adAccountsPAgeDescription]: "translation_missing",
  //  [keys.A_permissions]: "translation_missing",
  //  [keys.A_preferences]: "translation_missing",
  //  [keys.A_rename]: "translation_missing",
  //  [keys.A_options]: "translation_missing",
  //  [keys.A_adminHeaderInfo]: "translation_missing",
  //  [keys.A_funnels]: "translation_missing",
  //  [keys.A_Integrations]: "translation_missing",
  //  [keys.A_integrationsPageTitle]: "translation_missing",
};

export default translate;
