// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.WIZ_continueLabelWithHelp]:
    "Para continuar, terminar as etapas necessárias",
  [keys.WIZ_billingPlanTitle]: "Selecione um plano",
  [keys.WIZ_billingMethodTitle]: "Adicione detalhes de pagamento",
  [keys.WIZ_billingTermsTitle]: "Aceite termos e condições",
  [keys.WIZ_paymentMethodTitleStep1]: "1. Selecione método de pagamento",
  [keys.WIZ_paymentMethodCountrySelectLabel]: "2. Checkout",
  [keys.WIZ_paymentMethodCountrySelectLabel]:
    "Selecione seu país para ver métodos de pagamento disponíveis.",
  [keys.WIZ_termsTitle]: "Termos e condições da 360dialog:",
  [keys.WIZ_numberWasSetUp]: "Seu número <NUMBER> foi configurado com sucesso.",
  [keys.WIZ_connectInboxTitle]: "Terminar seu onboarding com get.chat",
  [keys.WIZ_connectInboxText]:
    "Para configurar sua caixa de entrada get.chat, siga as instruções do e-mail que você já deveria ter recebido. Sua conta WhatsApp Business está configurada e já conectada para que você possa começar a usar a caixa de entrada imediatamente.",
  [keys.WIZ_goToHubButtonLabel]: "Siga para o 360dialog Client Hub",
  [keys.WIZ_channelPageCheckboxText1]: "👤 Você tem uma conta pessoal de Meta",
  [keys.WIZ_channelPageCheckboxText1]:
    "📞 O número que você está conectando não está sendo usado atualmente com outra conta WhatsApp.",
  [keys.WIZ_channelPageCheckboxText3]:
    "📲 Você pode verificar a propriedade sobre o número mediante SMS ou telefonemas internacionais.",
  [keys.WIZ_channelPageCheckboxText3]:
    "🖋️ Você já tem uma conta comercial no Meta que está verificada.",
  [keys.WIZ_channelPageSubtitle1]:
    "Na etapa seguinte, você precisa configurar suas contas usando suas credenciais do Meta e seguindo as etapas fornecidas.",
  [keys.WIZ_channelPageSubtitle2]:
    "Depois disso, configuraremos automaticamente tudo para você e você poderá utilizar a API comercial WhatsApp.",
  [keys.WIZ_waitingText1]:
    "Estamos atualmente configurando sua própria instância de servidor, portanto, você estará pronto para começar a usar a API comercial WhatsApp em alguns minutos.",
  [keys.WIZ_waitingText2]:
    "Iniciando seu cliente WhatsApp Business API hospedado.",
  [keys.WIZ_channelTitle]: "Certifique-se de cumprir os seguintes requisitos.",
  [keys.WIZ_titleSpecifiedPermission]:
    "O parceiro está solicitando permissão para acessar o WhatsApp Business API para o seguinte número:",
  [keys.WIZ_titleUnspecifiedPermission]:
    "Selecione os números para os quais você deseja dar permissão ao parceiro para acessar a WhatsApp Business API.",
  [keys.WIZ_saveSelection]: "Salvar seleção",
  [keys.WIZ_grantPermission]: "Dar permissão",
  [keys.WIZ_optional]: "Opcional",
  [keys.WIZ_successTitle]: "Você conectou com sucesso sua conta 360dialog com",
  [keys.WIZ_successSubtitle1]:
    "Retornará para <PARTNER> automaticamente em 5 segundos",
  [keys.WIZ_successSubtitle2]: "Até lá, por favor não feche essa janela.",
  [keys.WIZ_successMainTitle]: "Applicação de partner autorizada!",
  [keys.WIZ_successTitleWithoutBrandName]:
    "Você acabou de conectar com sucesso sua conta 360dialog.",
  [keys.WIZ_successTitle1WithoutBrandName]:
    "Retornará automaticamente em 5 segundos",
  [keys.WIZ_permissionsPageWarningMessage]:
    "Foi solicitada a permissão para o número <NUMBER>, que não existe em sua conta 360dialog. Você deseja adicioná-lo agora? Caso contrário, selecione um número diferente abaixo.",
  [keys.WIZ_addThisNumber]: "Adicionar este número",
  [keys.WIZ_addSelected]: "Adicionar selecionado",
  [keys.WIZ_selectPlanTitle]: "Selecione seu plano",
  [keys.WIZ_selectPlanSubtitle]:
    "Apenas mais alguns passos até que você possa começar a conversar com seus clientes. Vamos começar com o básico.",
  [keys.WIZ_addNumberText]:
    "Opcionalmente, você também pode <BUTTON>adicionar um novo número agora mesmo<BUTTON>.",
  [keys.WIZ_selectedCount]: "<NUMBER> números selecionados",
  [keys.WIZ_selectedCountNewNumbers]: "<NUMBER> novos números selecionados",
  [keys.WIZ_welcomeToWABA]: "Bem vindo ao WhatsApp Business API!",
  [keys.WIZ_facebookAndWhatsAppBusAccount]:
    "Contas Comerciais do Meta e WhatsApp ",
  [keys.WIZ_accountsSuccessfullyCreated]: "Contas criadas com sucesso!",
  [keys.WIZ_authorizePartnerApp]: "Autorizar a aplicação do partner",
  [keys.WIZ_numbers]: "Numeros",
  [keys.WIZ_selectNumbers]: "Selecionar numeros",
  [keys.WIZ_setupFbAndWba]: "Configure as contas do Meta e WhatsApp Business ",
  [keys.WIZ_nextSteps]:
    "Nos próximos passos, você precisa configurar suas contas usando suas credenciais do Meta e seguindo os passos fornecidos. Você também precisará fornecer algumas informações de pagamento.",
  [keys.WIZ_companyInfoText]:
    "Primeiro, nós precisamos de algumas informações sobre a sua empresa.",
  [keys.WIZ_cardDetailsError]:
    "Erro de pagamento. Por favor, verifique os detalhes do seu cartão e tente novamente.",
  [keys.WIZ_insufficientFundsError]:
    "Erro de pagamento. Por favor, verifique se você tem fundos suficientes no cartão e tente novamente.",
  [keys.WIZ_tryAgain]: "Tente novamente",
  [keys.WIZ_transactionCancelled]:
    "A transação foi recusada. Por favor, tente novamente.",
  [keys.WIZ_transactionFailed]:
    "A transação não foi realizada. Por favor, tente novamente.",
  [keys.WIZ_cardBlocked]:
    "O cartão usado para a transação está bloqueado, por favor, introduza um cartão ativo e tente novamente.",
  [keys.WIZ_cardExpired]:
    "O cartão usado para a transação está expirado, por favor, introduza um cartão ativo e tente novamente.",
  [keys.WIZ_amountMismatch]:
    "Ocorreu uma imcompatibilidade durante o processo de transação. Por favor, tente novamente.",
  [keys.WIZ_cardInvalid]:
    "O número do cartão é incorreto ou inválido. Por favor, introduza dados válidos e tente novamente.",
  [keys.WIZ_paymentNotAllowed]:
    "Seu banco não suporta ou não permite este tipo de transação. Por favor, use um método de pagamento diferente de outro banco.",
  [keys.WIZ_cardLowBalance]:
    "O cartão não tem dinheiro suficiente para cobrir o valor a pagar. Adicione fundos ou introduza outro cartão e tente novamente.",
  //  [keys.WIZ_noPaymentMethod]: "translation_missing",
  //  [keys.WIZ_cloudHostingIOInfo]: "translation_missing",
  //  [keys.WIZ_accountSetUpIssuesMessage]: "translation_missing",
  //  [keys.WIZ_accountCreationFailed]: "translation_missing",
  //  [keys.WIZ_updatePaymentDetails]: "translation_missing",
  //  [keys.WIZ_noPendingChannels]: "translation_missing",
  //  [keys.WIZ_verifyOwnership]: "translation_missing",
  //  [keys.WIZ_somethingWrong]: "translation_missing",
  //  [keys.WIZ_NumberVerificationTitle]: "translation_missing",
  //  [keys.WIZ_NumberVerificationDesc]: "translation_missing",
  //  [keys.WIZ_success]: "translation_missing",
  //  [keys.WIZ_successMessage]: "translation_missing",
  //  [keys.WIZ_paymentMethodError]: "translation_missing",
};

export default translate;
