// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.WF_title]: "WhatsApp Business API Account Submission",
  [keys.WF_intro]:
    "Please provide information about the company and phone number that you would like to connect to the WhatsApp Business API. Please make sure that your company follows Meta's compliance policy.",
  [keys.WF_compliancePolicyLinkLabel]: "compliance policy",
  [keys.WF_personalInformation]: "Personal Information",
  [keys.C_email]: "Email",
  [keys.WF_emailTooltip]:
    "Email will be used for communication purposes and alerts about the WhatsApp Business API",
  [keys.C_fullName]: "Full Name",
  [keys.WF_businessDetails]: "Business Details",
  [keys.WF_legalCompanyName]: "Company Name",
  [keys.WF_fbBusinessManagerId]: "Meta Business Manager ID",
  [keys.WF_FbIdTip_error]: "Wrong Meta Business Manager ID",
  [keys.WF_FbIdTip_header]: "Find your business ID in Business Manager",
  [keys.WF_FBIDTip_text1]:
    "Your business ID corresponds to your Business Manager. You may need your business ID when you share access to information such as monthly invoices or a Meta pixel.",
  [keys.WF_FBIDTip_text2]: "Steps to find your business ID:",
  [keys.WF_FBIDTip_step1]: "Go to Business settings.",
  [keys.WF_FBIDTip_step2]: "Click Business info.",
  [keys.WF_FBIDTip_step3]:
    "Below Business Manager info, you'll see your ID number.",
  [keys.WF_messaging]: "Messaging",
  [keys.WF_whatsAppPhoneNumber]: "WhatsApp Phone Number",
  [keys.WF_whatsAppPhoneNumberTooltip1]:
    "Number to be used for the WhatsApp Business API Account.",
  [keys.WF_whatsAppPhoneNumberTooltip2]:
    "Please do not include your personal phone number.",
  [keys.WF_timezone]: "Timezone",
  [keys.WF_attention]: "Attention",
  [keys.WF_govAgencyText1]:
    "If you are a Governmental Agency, you must not submit an account using this submission form. First, you require the pre-approval from Meta for your use case. If you do not have approval from the Meta team to create this account or do not know whether you are a governmental case, please read our <LINK>https://docs.360dialog.com/waba-for-government-agencies | documentation and fill out the form<LINK>. We will do our best to assist you.",
  [keys.PI_perMonth]: "/ Month",
  [keys.PI_text1]:
    "Conversation costs are charged as of Meta/WhatsApp EUR pricing as stated under the <LINK>https://developers.facebook.com/docs/whatsapp/pricing | Meta Pricing Rules<LINK> and may be subject to prepayment.",
  [keys.PI_text2]: "-",
  [keys.PI_textUSD]:
    "Conversation costs are charged as of Meta/WhatsApp USD pricing as stated under the <LINK>https://developers.facebook.com/docs/whatsapp/pricing | Meta Pricing Rules<LINK> and may be subject to prepayment.",
  [keys.PI_billedMonthly]: "Billed monthly",
  [keys.PI_completeOrderButtonLabel]: "Complete order",
  [keys.WF_buttonLabelSave]: "Save",
  [keys.WF_paymentInfo]: "Payment",
  [keys.WF_submission]: "Submission in progress...",
  [keys.WF_thanksTitle]: "Thank you!",
  [keys.WF_thanksNext]:
    "You will receive an email with instructions on how to proceed with the verification process.",
  [keys.WF_thanksMob1]:
    "In the meantime you can head to the Meta Business Manager to accept our",
  [keys.WF_thanksMobQuotes]: "messaging on behalf",
  [keys.WF_thanksMob2]: "request",
  [keys.WF_thanksButtonLabel]: "Open requests",
  [keys.WF_0800error]:
    "You're trying to register a number starting with 0800. Usually, these numbers can't receive international calls, which is required for the registration process. If this is the case, please provide a different number.",
  [keys.WF_paymentCardOwnerName]: "Name on credit card",
  [keys.WF_paymentCreditCard]: "Credit card",
  [keys.WF_paymentCardOwnerNamePlaceholder]:
    "If name differs from applicant name",
  [keys.WF_hubAccess]: "360dialog Client Hub Access",
  [keys.WF_hubAccessHelp]:
    "After the submission you will be able to access our 360dialog Client Hub, where you can manage your WhatsApp Business Account.",
  [keys.WF_hubAccessHelpPassword]:
    "Please select a password, which you will use to sign in to the 360dialog Client Hub.",
  [keys.WF_passwordLabel]: "Select a password",
  [keys.WF_repeatPasswordLabel]: "Repeat your password",
  [keys.WF_passwordError1]: "Password must be at least 8 characters.",
  [keys.WF_passwordError2]:
    "Password must contain at least one lower case letter (a-z).",
  [keys.WF_passwordError3]:
    "Password must contain at least one upper case letter (A-Z).",
  [keys.WF_passwordError4]:
    "Password must contain at least one digit (e.g. 0-9) or one special character (e.g. !#$%&?).",
  [keys.WF_passwordRepeatError]: "Passwords don't match.",
  [keys.WF_cardError]: "Please provide a valid credit card",
  [keys.WF_passwordErrorZxcvbn]:
    "Add another word or two. Uncommon words are better.",
  [keys.CON_title]: "Connect your Meta Business Account",
  [keys.CON_textIntro]:
    "To continue, you will need to access your company's Meta business account. By clicking the 'Continue with Meta' button below you can:",
  [keys.CON_bullet1]:
    "Create new or select existing Meta and WhatsApp Business accounts.",
  [keys.CON_bullet2]:
    "Provide a display name and description for your WhatsApp business profile.",
  [keys.CON_bullet3]:
    "Connect and verify your phone number to use for the WhatsApp API access.",
  [keys.CON_numberHelpText]:
    "Do not use a number that is currently connected to an existing WhatsApp account. This includes numbers that are connected to the WhatsApp Business App. You will not be able to finish the registration process.",
  [keys.CON_numberHelpLink]: "Learn more about your WhatsApp phone number",
  [keys.CON_buttonLabel]: "Continue with Meta",
  [keys.CAN_title]: "How can we help,",
  [keys.CAN_textIntro]:
    "It seems like you did not finish the Meta onboarding widget. But don't worry: You can restart any time and try again. We're here to help you through the process.",
  [keys.CAN_faq]: "Frequently asked questions",
  [keys.CAN_buttonHelp]:
    "Ready to try again? Click here to restart the onboarding widget.",
  [keys.WF_partnerIntro]: "You're applying as a user of <PARTNER>.",
  [keys.WF_userAlreadyExists]:
    "There is already an existing user with that email. To continue your onboarding or to add a new number to your WhatsApp Business API account, please log in to the 360dialog Client Hub.",
  [keys.CAN_livesupportTitle]: "Get individual support",
  [keys.CAN_livesupportText]:
    "Click the button on the right to schedule a meeting with one of our support agents, who will help you navigate the onboarding process.",
  [keys.CAN_livesupportButtonLabel]: "Schedule call",
  [keys.CAN_livesupportButtonLabelSuccess]: "Call scheduled",
  [keys.PI_selectedLabel]: "selected",
  [keys.SH_copied]: "Copied",
  [keys.SH_copyPageLink]: "Copy page link",
  [keys.SH_pageLink]: "Page link",
  [keys.PI_pricingPlanLinkLabel]:
    "Click here to learn more about our pricing plans.",
  [keys.WF_alreadyHaveAccount]: "Already have an account?",
  [keys.WF_login]: "Log in",
  [keys.WF_mobileHeading]: "We're sorry",
  [keys.WF_notSupportedMobile]:
    "Unfortunately, the Embedded Signup process is currently not supported for mobile devices. Make sure to use a desktop device and return.",
  [keys.WF_saveURL]: "Share current URL",
  [keys.WF_continueAnyway]: "Continue to classic signup",
  [keys.WF_sharedSuccessfully]: "URL was shared successfully",
  [keys.WF_copiedUrl]: "Copied URL to clipboard",
  [keys.CC_consentText]:
    "This website uses cookies to enhance the user experience.",
  [keys.CC_acceptButtonLabel]: "Accept",
  [keys.CC_declineButtonLabel]: "Decline",
  [keys.CC_title]: "Our website uses cookies",
  [keys.CC_text]:
    "We would like to use our own and third party cookies and similar technologies to enhance the user experience and marketing purposes. You can set your preferences by selecting the options below.",
  [keys.CC_cookies1text]:
    "<cookies1label> help with the basic functionality of our website, e.g. remember if you gave consent to cookies.",
  [keys.CC_cookies1label]: "Necessary cookies",
  [keys.CC_cookies2text]:
    "<cookies2label> make it possible to gather statistics about how you use our website, so we can make it better.",
  [keys.CC_cookies2label]: "Analytical cookies",
  [keys.CC_cookies3text]:
    "<cookies3label> make it possible to show you more relevant content and advertisements on our website and other platforms.",
  [keys.CC_cookies3label]: "Marketing cookies",
  [keys.CC_buttonLabelAcceptSelected]: "Accept selected",
  [keys.CC_buttonLabelAcceptNecessary]: "Accept necessary",
  [keys.CC_buttonLabelAcceptAll]: "Accept all",
  [keys.WF_currentBussinessSolutionsProvider]:
    "Current business solution provider",
  [keys.MIG_fbErrorWaitNewCode]:
    "You have to wait until you can re-trigger your code. Please wait <MINUTES> minutes before you try again.",
  [keys.CH_portExistingNumber]: "Port existing number",
  [keys.CH_portWhatsAppProfile]:
    "Port an existing WhatsApp Business Profile from any Provider to 360dialog.",
  [keys.CH_addNewNumber]: "Add a new phone number",
  [keys.CH_setUpWhatsAppProfile]:
    "Set up your WhatsApp Business Account and Profile with a number, which is not yet in use.",
  [keys.CH_welcomeTo360dialog]: "Welcome to 360dialog!",
  [keys.CH_pleaseSelect]:
    "Please select whether you want to port an existing WhatsApp Business API number to 360dialog or if you want to create a new account for a number that still isn't registered in the WhatsApp Business API.",
  [keys.CH_continueWithAccount]: "Continue with account creation",
  [keys.CAN_createTicket]: "Create a support ticket",
  [keys.CAN_ticketCreated]: "Ticket created",
  [keys.CON_contactSupportHelptext]:
    "Having trouble completing the onboarding?",
  [keys.WF_goToLogin]: "Go to login",
  [keys.WF_planSelection]: "Plan Selection",
  [keys.WF_monthly]: "Monthly",
  [keys.WF_yearly]: "Yearly",
  [keys.WF_month]: "Month",
  [keys.WF_year]: "Year",
  [keys.WF_billedMonthly]: "Billed Monthly",
  [keys.WF_months]: "months",
  [keys.WF_billedAt]: "Billed at",
  [keys.WF_selected]: "Selected",
  [keys.WF_select]: "Select",
  [keys.WF_basic]: "Basic",
  [keys.WF_professional]: "Professional",
  [keys.WF_forDevelopers]: "Favorite for developers",
  [keys.WF_scalablePlan]: "Our most scalable plan",
  [keys.WF_buttonLabelSubmit]: "Submit data",
  [keys.WF_buttonLabelCancel]: "Go back",
  [keys.WF_reviewData]: "Review your data",
  [keys.WF_passwordError5]: "Password must not contain email information",
  [keys.CC_openCookiePreferences]: "Open cookie preferences",
  [keys.CC_acceptCardLabel]:
    "To access our live chat support, please accept “Analytical Cookies”.",
  [keys.WF_freemailError]:
    'You can not register an account using the email domain "<DOMAIN>". Please use your business email address to register your account.',
  [keys.WF_termsIntro]:
    "By placing this “Order”, Client requests to use the WhatsApp Business Solution services (as further specified below, hereinafter the “Services”) provided by 360dialog GmbH, Torstr. 61, 10119 Berlin, Germany acting as an authorized “Solution Provider” (hereinafter “360dialog” or “BSP”). The “Services” means all services provided by 360dialog in connection with the setting up, the technical integration and the ongoing administration of WhatsApp Business Accounts (“WABA(s)”. By placing this Order, Client authorizes 360dialog to request Client WABA(s) from WhatsApp (as defined below) and to administrate the WABA(s) on behalf of Client. This shall include the passing on of information between WhatsApp and Client for the purposes of this Agreement.",
  [keys.WF_terms1]: "WABA MSISDNs",
  [keys.WF_terms1p1]:
    "Client hereby requests WABAs for the following MSISDN (including country code in this format +49):",
  [keys.WF_terms1p2]:
    "The approval of each WABA is at the discretion of WhatsApp. In this document, “WhatsApp” means WhatsApp Inc. and/or any of its affiliated parties within the Meta group which provides any services that part of the WhatsApp Business Solution.",
  [keys.WF_terms2]: "Compliance with WhatsApp Terms",
  [keys.WF_terms2p1p1]:
    "Client hereby warrants and represents to use the WABAs strictly in accordance with the applicable WhatsApp Business Solution Terms https://www.whatsapp.com/legal/business-solution-terms, the Meta Client Terms for WhatsApp as attached as Exhibit 2, as well as all other applicable WhatsApp terms, guidelines and policies, most importantly",
  [keys.WF_terms2p1and]: "and",
  [keys.WF_terms2p1p2]: "altogether the “WhatsApp Terms”.",
  [keys.WF_terms2p2]:
    "Client acknowledges and agrees that WhatsApp may modify the WhatsApp Terms and/or release additional terms, guidelines and policies, and upon their release, such additional terms, guidelines and policies shall be part of the WhatsApp Terms.",
  [keys.WF_terms2p3]:
    "Client indemnifies 360dialog from any damages, costs and harm causes by a breach of the WhatsApp Terms.",
  [keys.WF_terms2p4]:
    "WABA(s) are for use by Client only. Client represents and warrants that Client will not distribute, sell, resell, rent or otherwise grant access to the WABA(s) to any third party for any purposes outside the scope of this Order.",
  [keys.WF_terms3]: "Use of the Services",
  [keys.WF_terms3p1]:
    "The Services (as defined above) are provided by 360dialog to the Client. The use of the Services is subject to the 360dialog Terms of Use attached as Exhibit 1. This Order and the 360dialog Terms shall be referred to as the “Agreement”.",
  [keys.WF_terms3p2]:
    "According to the WhatsApp Terms, Clients are entitled to migrate their WABA(s) to a different WABA Business Solution Provider. Any efforts required by 360dialog in connection with such request are subject to the 360dialog Pricelist.",
  [keys.WF_terms4]: "Miscellaneous",
  [keys.WF_terms4p1]:
    "This Agreement shall be governed by and construed in accordance with the laws of the Federal Republic of Germany with the UN Convention on Contracts for the International Sale of Goods and conflicts-of-laws rules being excluded.",
  [keys.WF_terms4p2]:
    "The place of performance and venue is Berlin, Germany. However, 360dialog reserves the right to any other legally admissible venue.",
  [keys.WF_terms4p3]:
    "If any provision of this Agreement is held by a court of competent jurisdiction to be contrary to applicable laws, then the remaining provisions of this Agreement, if capable of substantial performance, shall remain in full force and effect. The affected clause shall be replaced by one that most nearly reflects the original intent of the parties.",
  [keys.WF_terms5]: "360dialog Terms and Pricing",
  [keys.WF_terms5p1]:
    "Monthly License fee for hosting of the WhatsApp Business API is at a rate of 50.00USD/Number/Month.",
  [keys.WF_terms5p2]:
    "Terms of the Monthly Licensee fee is month-by-month and can be terminate with 30 days notice.",
  [keys.WF_terms5p3]:
    "Monthly License fees for WhatsApp Business API hosting will be billed based on calendar month.",
  [keys.WF_terms5p4]:
    "360dialog bills the client only for WhatsApp Business API after deployment of client’s hosting stack.",
  [keys.WF_terms5p5]:
    "If a hosting only starts other than at the 1st of a calendar month, it will be billed only pro rata depending on the days the hosting activated.",
  [keys.WF_terms5p6]:
    "All licence fees are invoiced at the beginning of the corresponding month.",
  [keys.WF_terms5p7]:
    "WhatsApp Template Message Pricing Costs for Template Messages are not included in the monthly license fee and are charged as of Meta/ Whatsapp pricing in EUR as stated under https://developers.facebook.com/docs/whatsapp/pricing/",
  [keys.WF_terms5p8]: "All fees are exclusive of VAT, if applicable.",
  [keys.WF_termsLabel]: "Terms",
  [keys.WF_termsCheckbox1]:
    "I agree to the Exhibit 1 - 360dialog Terms of Use.",
  [keys.WF_termsCheckbox1Detail]: "Last modified:",
  [keys.WF_termsCheckbox2]:
    "I have acknowledged and agree to Exhibit 2 - Meta Client Terms for WhatsApp.",
  [keys.WF_termsCheckbox3]: "I agree to the Exibit 3 - 360dialog Pricing.",
  [keys.WF_verifiedBsp]:
    "360dialog is a verified WhatsApp Solution Provider and has been given the right by Meta/WhatsApp to grant their clients access to the WhatsApp Business API.",
  [keys.WF_buttonLabelAccept]: "Accept terms",
  [keys.CON_sessionExp]:
    'Your session with Meta has expired. Please repeat the process by clicking the "Continue with Meta" button.',
  [keys.VER_checkEmailTitle]: "Check your email!",
  [keys.VER_submit]: "Submit",
  [keys.VER_bullet1]: "To proceed, you first need to verify your email.",
  [keys.VER_bullet2]: "A one time passcode has been sent to <EMAIL>.",
  [keys.VER_bullet3]:
    "Please enter the passcode below to verify your email address.",
  [keys.VER_inputLabel]: "One time passcode",
  [keys.VER_inputPlaceholder]: "Enter the passcode here",
  [keys.VER_inputError]: "The submitted code is not correct.",
  [keys.VER_closeWindowWarning]:
    "Please make sure to not close this window. Your data will not be saved until you submit the verification code.",
  [keys.VER_newCodeSentToEmail]: "A new code was sent to your email",
  [keys.AI_almostDone]: "You're almost done, <USERNAME>",
  [keys.AI_addInfo]: "Add additional company information.",
  [keys.AI_completeInfo]: "Complete information",
  [keys.AI_companyInfo]:
    "To proceed, we need some information about your company",
  [keys.AI_companyNameLabel]: "Legal company name",
  [keys.AI_companyNamePlaceholder]: "Enter the legal company's name",
  [keys.AI_companyAddressLabel]: "Company address",
  [keys.AI_companyAddressPlaceholder]: "Enter the company's address",
  [keys.AI_selectCountry]: "Select country",
  [keys.AI_vatIdLabel]: "VAT ID",
  [keys.AI_vatIdPlaceholder]: "Enter the VAT ID",
  [keys.AI_companyVertical]: "Company's vertical",
  [keys.FS_accessCompanyFBM]:
    "To continue, you will need to <strong>access your company's Meta Business Manager</strong> and do the following:",
  [keys.FS_connectNumber]:
    "Connect and verify your phone number to use the WhatsApp Business API.",
  [keys.FS_addInfoToBusinessManager]:
    "To have an active account you must add your information in the Business Info section of Business Manager Settings.",
  [keys.FS_fbCommercePolicy]:
    "This information is used by Meta to check compliance with the Commerce Policy. If it's not correctly set up, the account will be immediately blocked.",
  [keys.FS_embeddedSignupInfo]:
    "<LINK>https://docs.360dialog.com/docs/embedded-signup | More information<LINK>",
  [keys.WF_fullName]: "Enter your full name",
  [keys.WF_noAccount]: "No account yet?",
  [keys.WF_signUpHere]: "Sign up here",
  [keys.WF_loginHere]: "Login here",
  [keys.WF_passwordError6]: "Password must not contain user information",
  [keys.WF_comapnyNameTooltip]:
    "Please enter your Legal Company Name. Your WhatsApp profile name can be chosen later and can differ if conditions apply",
  [keys.WF_vatIdTooltip]:
    "VAT ID required for EU based companies only. Please provide full VAT ID with prefix. Example - ES25084476",
  [keys.WF_vatIdNotValid]: "VAT ID is not valid",
  [keys.WF_noVatId]: "My company does not have a VAT ID",
  [keys.WF_noVATMessage]:
    "If your company does not have a sales tax identification number or this one is not valid, we will charge you German sales tax.",
  [keys.WF_vatInfoUpdated]: "Company and VAT info updated",
  [keys.WF_governmentalCheck]: "Governmental check",
  [keys.WF_notGovernmental]: "I am not a Governmental Agency",
  [keys.WF_facebookBusinessSetup]: "Meta Business Setup",
  [keys.WF_ukraineExcludedAreasNote]:
    "Company is not a part of the Crimea, Donetsk and Luhansk regions.",
  [keys.FS_comeBackFromMeta]: "Come back when you",
  [keys.FS_completeMataOnboarding]: "Complete Meta's Onboarding",
  [keys.FS_completeMataOnboardingInfo]:
    "As soon as you finish the journey in Meta, we will get your WhatsApp number ready for you to start implementing your desired solution.",
  [keys.CC_disabledCookiePreferences]:
    "Cookie preference disabled for non GDPR compliant countries",
  [keys.CON_bullet1]: "Create a new or select existing Meta accounts.",
  [keys.CON_bullet2]:
    "Create a new or select an existing WhatsApp Business Account",
  [keys.CON_bullet2a]:
    "The selected account must not contain any other active phone numbers",
  [keys.CON_bullet2b]:
    "The selected account must not have a payment method set",
  [keys.CON_bullet3]:
    "Provide a display name and description for your WhatsApp business profile.",
  [keys.CON_bullet4]: "Provide the phone number you want to use via API",
  [keys.CON_bullet4a]:
    "You need to receive a call or a message to verify the ownership of the number",
};

export default translate;
