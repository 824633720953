// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  //  [keys.NAV_whatsappAccounts]: "translation_missing",
  //  [keys.NAV_partners]: "translation_missing",
  //  [keys.NAV_integrations]: "translation_missing",
  //  [keys.NAV_support]: "translation_missing",
  //  [keys.NAV_profile]: "translation_missing",
  //  [keys.NAV_billing]: "translation_missing",
  //  [keys.NAV_helpAndFeedback]: "translation_missing",
  //  [keys.NAV_preferences]: "translation_missing",
  //  [keys.NAV_signOut]: "translation_missing",
  //  [keys.NAV_dock]: "translation_missing",
  //  [keys.NAV_undock]: "translation_missing",
  //  [keys.NAV_templateBilling]: "translation_missing",
  //  [keys.NAV_conversationBilling]: "translation_missing",
  //  [keys.NAV_billingText]: "translation_missing",
  //  [keys.NAV_insights]: "translation_missing",
  //  [keys.NAV_insightsAndBilling]: "translation_missing",
  //  [keys.NAV_organizationSettings]: "translation_missing",
  //  [keys.NAV_connectedApps]: "translation_missing",
  //  [keys.NAV_notifications]: "translation_missing",
  //  [keys.NAV_logout]: "translation_missing",
  //  [keys.NAV_gettingStarted]: "translation_missing",
  //  [keys.NAV_roadmap]: "translation_missing",
  //  [keys.NAV_billinOptions]: "translation_missing",
  //  [keys.NAV_company]: "translation_missing",
  //  [keys.NAV_adAccounts]: "translation_missing",
  //  [keys.NAV_pageUnavailable]: "translation_missing",
  //  [keys.NAV_team]: "translation_missing",
};

export default translate;
