// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.NAV_whatsappAccounts]: "WhatsApp Accounts",
  [keys.NAV_partners]: "Partner",
  [keys.NAV_integrations]: "Integrationen",
  [keys.NAV_support]: "Support",
  [keys.NAV_profile]: "Profil",
  [keys.NAV_billing]: "Zahlungsoptionen",
  [keys.NAV_helpAndFeedback]: "Hilfe und Feedback",
  [keys.NAV_preferences]: "Einstellungen",
  [keys.NAV_signOut]: "Abmelden",
  [keys.NAV_dock]: "Navigation andocken",
  [keys.NAV_undock]: "Navigation abdocken",
  [keys.NAV_templateBilling]: "Templates",
  [keys.NAV_conversationBilling]: "Konversationen",
  [keys.NAV_billingText]: "Zahlungen",
  [keys.NAV_insights]: "Analysen",
  [keys.NAV_insightsAndBilling]: "Analysen & Kosten",
  [keys.NAV_organizationSettings]: "Unternehmenseinstellungen",
  [keys.NAV_connectedApps]: "Verbundene Apps",
  [keys.NAV_notifications]: "Benachrichtigungen",
  [keys.NAV_logout]: "Abmeldung",
  [keys.NAV_gettingStarted]: "Erste Schritte",
  [keys.NAV_roadmap]: "Roadmap",
  //  [keys.NAV_billinOptions]: "translation_missing",
  //  [keys.NAV_company]: "translation_missing",
  //  [keys.NAV_adAccounts]: "translation_missing",
  //  [keys.NAV_pageUnavailable]: "translation_missing",
  //  [keys.NAV_team]: "translation_missing",
};

export default translate;
