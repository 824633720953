import React from "react";
import Button from "@gcp-artifact/compass/lib/components/updated/button";
import styled from "styled-components";
import { TextButton } from "@/compass/components";
import Link from "@gcp-artifact/compass/lib/components/updated/link";
import type { ILink } from "@gcp-artifact/compass/lib/components/updated/link/LinkTypes";
import { ButtonVariantType } from "@gcp-artifact/compass/lib/components/updated/button/ButtonTypes";

export const Links = styled(Link)<{ hideUnderline?: boolean }>`
  &:hover {
    background: var(--snow-darker);
    color: var(--whale-dark);
  }
  box-shadow: ${(props) => props.hideUnderline && "none"};
`;

export const replacePlaceholderStringBold = (
  text: string,
  placeholder: string,
  value: string
) => {
  const arr = text.split(placeholder, 2);
  return (
    <React.Fragment>
      {arr[0]}
      <b>{value}</b>
      {arr[1]}
    </React.Fragment>
  );
};

export const replaceBoldPlaceholder = (text: string, placeholder: string) => {
  const arr = text.split(placeholder);
  return (
    <React.Fragment>
      {arr[0]}
      <b>{arr[1]}</b>
      {arr[2]}
    </React.Fragment>
  );
};

export const replacePlaceholderString = (
  text: string,
  placeholder: string,
  value: string
) => {
  const arr = text.split(placeholder, 2);
  if (arr[1]) return arr[0] + value + arr[1];
  else return arr[0] + value;
};

export const replacePlaceholderElement = (
  text: string,
  placeholder: string,
  value: React.ReactNode
) => {
  const arr = text.split(placeholder, 2);
  return (
    <React.Fragment>
      {arr[0]} {value} {arr[1]}
    </React.Fragment>
  );
};

export const replacePlaceholderLink = (
  text: string,
  inline?: boolean,
  variant?: "primary" | "subtle-whale" | "subtle-green",
  as?: string,
  hideUnderline?: boolean
): React.ReactNode => {
  if (text.includes("<LINK>")) {
    const arr = text.split("<LINK>", 3);
    let linkElementArr = arr[1].split("|");
    linkElementArr = linkElementArr.map((el) => el.trim());
    if (as === "span") {
      return (
        <span>
          {arr[0]}
          <Links
            variant={variant ? variant : "subtle-whale"}
            inline={Boolean(inline)}
            href={linkElementArr[0]}
            target="_blank"
          >
            {linkElementArr[1]}
          </Links>
          {arr[2]}
        </span>
      );
    } else {
      return (
        <React.Fragment>
          {arr[0]}
          <Links
            variant={variant ? variant : "subtle-whale"}
            inline={Boolean(inline)}
            href={linkElementArr[0]}
            target="_blank"
            hideUnderline={hideUnderline}
          >
            {linkElementArr[1]}
          </Links>
          {arr[2]}
        </React.Fragment>
      );
    }
  } else {
    return text;
  }
};

export const replacePlaceholderLinkWithHref = (
  text: string,
  href: string,
  inline?: boolean,
  variant?: ILink["variant"],
  as?: string,
  target = "_blank"
): React.ReactNode => {
  const arr = text.split("<LINK>", 3);
  if (as === "span") {
    return (
      <span>
        {arr[0]}
        <Link
          variant={variant ? variant : "subtle-whale"}
          inline={Boolean(inline)}
          href={href}
          target={target}
        >
          {arr[1]}
        </Link>
        {arr[2]}
      </span>
    );
  } else {
    return (
      <React.Fragment>
        {arr[0]}
        <Link
          variant={variant ? variant : "subtle-whale"}
          inline={Boolean(inline)}
          href={href}
          target={target}
        >
          {arr[1]}
        </Link>
        {arr[2]}
      </React.Fragment>
    );
  }
};

export const replacePlaceholderWithButton = (
  text: string,
  onClick: () => void,
  variant?: ButtonVariantType,
  size?: "string",
  inline?: boolean,
  disabled?: boolean
): React.ReactNode => {
  const arr = text.split("<BUTTON>", 3);
  return (
    <React.Fragment>
      {arr[0]}
      <Button
        variant={variant ? variant : "secondary"}
        inline={Boolean(inline)}
        onClick={onClick}
        disabled={disabled}
        size={size}
      >
        {arr[1]}
      </Button>
      {arr[2]}
    </React.Fragment>
  );
};

export const replacePlaceholderWithTextButton = (
  text: string,
  onClick: () => void,
  variant?: "primary" | "secondary",
  showUnderline?: boolean,
  backgroundColor?: string,
  inline?: boolean,
  size?: string,
  disabled?: boolean
): React.ReactNode => {
  const arr = text.split("<BUTTON>", 3);
  return (
    <React.Fragment>
      {arr[0]}
      <TextButton
        onClick={onClick}
        variant={variant}
        backgroundColor={backgroundColor}
        disabled={disabled}
        inline={inline}
        size={size}
        showUnderline={showUnderline}
      >
        {arr[1]}
      </TextButton>
      {arr[2]}
    </React.Fragment>
  );
};

export const replacePlaceholderTime = (
  text: string,
  placeholderHour: string,
  placeholderMin: string,
  time: string[]
) => {
  const replaceHour = text.replace(placeholderHour, time[0]);
  return replaceHour.replace(placeholderMin, time[1]);
};

export const replaceNewLine = (text: string) => {
  const arr = text.split("\n"); //.join(`${<br />}`);
  return (
    <React.Fragment>
      {arr.map((item, idx) => {
        return (
          <React.Fragment key={"text-element-" + idx}>
            {item}
            {idx < arr.length - 1 && <br />}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export const replacePlaceholderStringMultiple = (
  text: string,
  replacements: { [key: string]: string }
): string => {
  let result = text;

  for (const [placeholder, value] of Object.entries(replacements)) {
    const regex = new RegExp(placeholder, "g");
    result = result.replace(regex, value);
  }

  return result;
};

/**
 * Replaces placeholders in a string with actual HTML content and returns
 * a React element with the HTML safely inserted using `dangerouslySetInnerHTML`.
 *
 * @param content - The string containing placeholders to be replaced with HTML content.
 * @returns A React element with the HTML content safely inserted.
 *
 * @remarks
 * This function uses `dangerouslySetInnerHTML` to insert HTML content directly
 * into the React element. Ensure that the content is safe and does not contain
 * any untrusted or potentially malicious code to avoid XSS vulnerabilities.
 *
 * @example
 * ```tsx
 * const htmlString = "Business-initiated conversation limits are: <b>250</b> · <b>1K</b> · <b>10K</b> · <b>100K</b>";
 * const element = replacePlaceholdersWithHTML(htmlString);
 * ```
 */
export const replacePlaceholdersWithHTML = (content: string): JSX.Element => {
  return <span dangerouslySetInnerHTML={{ __html: content }} />;
};
