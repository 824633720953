// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.ERR_templateRenameExists]:
    "Невозможно переименовать шаблон. Шаблон с таким именем уже существует. Пожалуйста, укажите другое имя.",
  [keys.ERR_noStripeCustomer]:
    "Не удалось открыть параметры оплаты. Ваш платежный аккаунт еще не настроен. Пожалуйста, обратитесь в службу поддержки чтобы сделать это.",
  [keys.ERR_weAreSorry]: "Нам очень жаль",
  [keys.ERR_internalIssuesTeamNotified]:
    "Похоже, у нас какие-то внутренние проблемы. Наша команда была уведомлена. Если вы хотите помочь, вы можете описать, что произошло ниже.",
  [keys.ERR_reloadPage]: "Перезагрузите страницу",
  [keys.ERR_letUsKnowWhatHappened]: "Дайте нам знать, что случилось",
  [keys.ERR_failedToGetCountry]: "Не удалось выполнить запрос страны",
  [keys.ERR_errorLoadingPaymentNotification]:
    "Ошибка при загрузке PaymentNotification",
  [keys.ERR_userNotFound]:
    "Мы не смогли найти пользователя с этим адресом электронной почты. Пожалуйста, проверьте еще раз. Если вы хотите зарегистрировать новую учетную запись, обратитесь к своему партнеру по интеграции или посетите сайт 360dialog.com",
  [keys.ERR_partnerNotPermitted]:
    "К сожалению, данному партнеру не разрешено использовать embedded signup. Пожалуйста свяжитесь с нами",
  [keys.ERR_inputFields]: "Ошибка в полях ввода",
  [keys.ERR_fbConnectionError]:
    "Что-то пошло не так при попытке подключить ваш аккаунт Meta.",
  [keys.ERR_fbNoRegisteredEmail]:
    "В вашей учетной записи не зарегистрирован адрес электронной почты, добавьте его в свою учетную запись Meta.",
  [keys.ERR_userNoExists]:
    "Этот пользователь не существует. Пожалуйста, зарегистрируйтесь через приложение вашего партнера или 360dialog.com",
  [keys.ERR_addFundsNumber]: "Невозможно пополнить баланс некорректных номеров",
  [keys.ERR_autoRenewalNumber]:
    "Невозможно изменить настройки автоматического обновления для некорректных номеров",
  [keys.ERR_stripeErrorNote]:
    "Мы столкнулись с ошибкой при загрузке Stripe-скриптов. Пожалуйста, обновите страницу и попробуйте еще раз.",
  [keys.ERR_filterOptionsErrorNote]:
    "Мы обнаружили ошибку при загрузке параметров фильтра языка и часового пояса. Пожалуйста, обновите страницу и попробуйте еще раз.",
  [keys.ERR_publicDataErrorText]:
    "Страница не может быть загружена правильно. Пожалуйста, попробуйте еще раз, перезагрузив страницу. {{break}} Если проблема не устранена, просмотрите нашу страницу статуса через {{statusPageLink}} или обратитесь в нашу службу поддержки.",
  //  [keys.ERR_somethingUnexpected]: "translation_missing",
  //  [keys.ERR_phoneRegistrationError]: "translation_missing",
  //  [keys.ERR_userWithNoClientPermissionError]: "translation_missing",
};

export default translate;
